import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import {
  Avatar,
  Button,
  CssBaseline,
  Typography,
  Container,
  CircularProgress,
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import { selectMyProfile } from '../../../../stores/slices/userSlice';
import { PaymentUpdateStyle } from './PaymentUpdate.style';

const PaymentUpdate: React.VFC = () => {
  const classes = PaymentUpdateStyle();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const userData = useSelector(selectMyProfile);
  const customerId = userData.customerId;
  const redirectToCustomerPortal = async () => {
    setLoading(true);
    setDisabled(true);
    const apiName = 'stripeAPI';
    const apiEndpoint = '/customer-portal';
    const body = {
      customerId: customerId,
    };
    const res = await API.post(apiName, apiEndpoint, { body });
    const url = res.url;
    window.location.href = url;
    setLoading(false);
    setDisabled(false);
  };

  return (
    <div className={classes.root}>
      {/* <CheckoutForm /> */}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PaymentIcon />
          </Avatar>
          <Typography align="center" className={classes.titleText}>
            本サービスを利用するためには以下のボタンを
            <br />
            クリックして遷移先のページで決済情報の更新を
            <br />
            お願いいたします
          </Typography>
          <Button
            type="button"
            onClick={() => {
              redirectToCustomerPortal();
            }}
            fullWidth
            disabled={disabled}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {loading ? <CircularProgress size={28} /> : '決済情報を更新する'}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PaymentUpdate;
