import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  UserState,
  PROPS_CREATE_USER,
} from '../../interfaces/slice-props.interface';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { GetUserQuery } from '../../API';

const initialState: UserState = {
  authData: { email: '' },
  myProfile: {
    __typename: 'User',
    id: '',
    lastName: '',
    firstName: '',
    owner: '',
    email: '',
    payment: false,
    customerId: '',
    createdAt: '',
    updatedAt: '',
  },
};

/* ============================
          ユーザ情報登録
============================ */
export const createUserApi = async (data: PROPS_CREATE_USER) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.createUser, {
        input: {
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          payment: false,
          id: data.userSub,
          owner: data.userSub,
          customerId: 'noCheckOut',
          testNumber: 1,
        },
      })
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/* ============================
        プロフィール取得
============================ */
export const fetchUserApi = createAsyncThunk(
  'user/getProfile',
  async (id: string) => {
    try {
      const res = await API.graphql(
        graphqlOperation(queries.getUser, { id: id })
      );
      if ('data' in res && res.data) {
        const data = res.data as GetUserQuery;
        if (data.getUser) {
          const userData = data.getUser;
          return userData;
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getAuthEmail: (state, action) => {
      state.authData.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserApi.fulfilled, (state, action) => {
      state.myProfile = { ...state.myProfile, ...action.payload };
    });
  },
});

export const { getAuthEmail } = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthEmail = (state: RootState): string =>
  state.user.authData.email;

// TODO: 後々[any]型を直す
export const selectMyProfile = (state: RootState): any => state.user.myProfile;

export default userSlice.reducer;
