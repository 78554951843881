import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  createUserApi,
  getAuthEmail,
} from '../../../../stores/slices/userSlice';
import { PROPS_SIGNUP } from '../../../../interfaces/slice-props.interface';
import { AppDispatch } from '../../../../stores/store';
import { signUp } from '../utils/userAuth';
import { SignUpStyle } from './SignUp.style';
import history from '../../../../history';
import paths from '../../../../config/paths';

const SignUp: React.VFC = () => {
  const classes = SignUpStyle();
  const dispatch: AppDispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { register, handleSubmit, errors } = useForm<PROPS_SIGNUP>();
  const handleSignUp = async (data: PROPS_SIGNUP) => {
    setLoading(true);
    setDisabled(true);
    const { email, password, lastName, firstName } = data;
    try {
      const res = await signUp(email, password, lastName, firstName);
      const passData = {
        userSub: res.userSub,
        email: data.email,
        lastName: data.lastName,
        firstName: data.firstName,
      };
      await createUserApi(passData);
      dispatch(getAuthEmail(email));
      history.push(paths.userAuth.confirmSignUp);
      setLoading(false);
      setDisabled(false);
    } catch (error) {
      console.log(error);
      switch (error.message) {
        case 'Password did not conform with policy: Password not long enough':
          alert('パスワードは8文字以上で入力してください');
          break;
        case 'Password did not conform with policy: Password must have uppercase characters':
          alert('パスワードは大文字を含めてください');
          break;
        case 'Password did not conform with policy: Password must have lowercase characters':
          alert('パスワードは小文字を含めてください');
          break;
        case 'Password did not conform with policy: Password must have numeric characters':
          alert('パスワードは数字を含めてください');
          break;
        case 'An account with the given email already exists.':
          alert('すでに登録済みのアカウントです');
          break;
        default:
          alert('新規登録に失敗しました。時間をおいた後、再度お試しください');
      }
      setLoading(false);
      setDisabled(false);
    }
  };
  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.titleText}>新規登録</Typography>
          <form onSubmit={handleSubmit(handleSignUp)} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="メールアドレス"
                  disabled={disabled}
                  name="email"
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'メールアドレスを入力してください',
                    },
                    pattern: {
                      value: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                      message: 'メールアドレスを正しい形式で入力してください',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="lastName"
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="姓"
                  disabled={disabled}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                  inputRef={register({
                    required: {
                      value: true,
                      message: '姓を入力してください',
                    },
                    maxLength: {
                      value: 10,
                      message: '姓は10文字以内で入力してください',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  label="名"
                  disabled={disabled}
                  name="firstName"
                  autoComplete="lname"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                  inputRef={register({
                    required: {
                      value: true,
                      message: '名を入力してください',
                    },
                    maxLength: {
                      value: 10,
                      message: '名は10文字以内で入力してください',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="パスワード"
                  disabled={disabled}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'パスワードを入力してください',
                    },
                    minLength: {
                      value: 8,
                      message: 'パスワードは8文字以上で入力してください',
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      color="primary"
                      name="terms"
                      disabled={disabled}
                      required
                    />
                  }
                  label="利用規約/プライバシーポリシーに同意する"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={disabled}
              className={classes.submit}
            >
              {loading ? <CircularProgress size={28} /> : '登録する'}
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to={paths.userAuth.signIn}>
                  すでにアカウントを持っている方
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default SignUp;
