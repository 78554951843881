import { createStyles, makeStyles, Theme } from '@material-ui/core';

const PaymentThankStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    alert: {
      width: 600,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        width: 350,
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
    },
    titleText: { fontSize: theme.typography.h4.fontSize, fontWeight: 500 },
    subText: {
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(1),
    },
    wrapper: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
      padding: '12px 16px',
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 600,
    },
    link: {
      width: '100%',
      textDecoration: 'none',
      color: '#FFFFFF',
    },
    image: {
      maxWidth: '100%',
    },
  })
);

export default PaymentThankStyle;
