import { createMuiTheme } from '@material-ui/core';

// 色の変数を追加したいときにここで型を再定義する
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    bgGray: Palette['primary'];
  }
  interface PaletteOptions {
    bgGray: PaletteOptions['primary'];
  }
}

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0, // スマホ用
      sm: 600, // タブレット用
      md: 960, // PC用
      lg: 1280,
      xl: 1920,
    },
  },
  // classのstyleを上書き
  overrides: {
    MuiButton: {
      root: {
        // ボタン内アルファベット文字を大文字変換しない
        textTransform: 'none',
      },
    },
  },
  palette: {
    action: {
      active: 'rgba(0,0,0,0.54)',
      hover: 'rgba(0,0,0,0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0,0,0, 0.14)',
      disabled: 'rgba(0,0,0, 0.26)',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      main: '#6F00FF',
    },
    // secondary: {
    //   main: '#111111',
    // },
    error: { main: '#D45D87' },
    // warning: {},
    // info: {},
    // success: {},
    bgGray: { main: '#797979' },
  },

  typography: {
    fontFamily: [
      'ヒラギノ角ゴシック',
      'Arial',
      '游ゴシック体',
      'YuGothic',
      'メイリオ',
      'Meiryo',
      'sans-serif',
    ].join(','),
    // 後々整合性合わせる
    // フォントサイズはここの範囲内のものを使うこと //
    h1: { fontSize: 34 },
    h2: { fontSize: 24 },
    h3: { fontSize: 20 },
    h4: { fontSize: 16 },
    h5: { fontSize: 14 },
    h6: { fontSize: 12 },
    body1: { fontSize: 11 },
    body2: { fontSize: 9 },
    subtitle1: {
      fontSize: 8,
    },
    subtitle2: {
      fontSize: 6,
    },
    // フォントサイズはここの範囲内のものを使うこと //
  },
});

export default theme;
