import React from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import AfterRoutes from './afterRoutes/AfterRoutes';
import BeforeRoutes from './beforeRoutes/BeforeRoutes';
import Footer from '../views/components/footer/Footer';
import Header from '../views/components/header/Header';

import RoutesStyle from './Routes.style';
import { useTracking } from '../utils/useTracking';

interface PropTypes {
  token: CognitoUserSession | string | null;
}

const Routes: React.VFC<PropTypes> = ({ token }) => {
  const classes = RoutesStyle();
  useTracking('G-MSG6MVBKH9');
  return (
    <div className={classes.root}>
      <div>
        <Header />
      </div>
      <div className={classes.main}>
        {token === 'No current user' ? <BeforeRoutes /> : <AfterRoutes />}
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Routes;
