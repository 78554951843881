import {
  stripeBasicLumpClientReferenceId,
  stripeBasicLumpPriceId,
  stripeBasicSubscriptionClientReferenceId,
  stripeBasicSubscriptionPriceId,
  stripeMinimumSupportLumpClientReferenceId,
  stripeMinimumSupportLumpPriceId,
  stripeMinimumSupportSubscriptionClientReferenceId,
  stripeMinimumSupportSubscriptionPriceId,
  stripeFullSupportLumpClientReferenceId,
  stripeFullSupportLumpPriceId,
  stripeFullSupportSubscriptionClientReferenceId,
  stripeFullSupportSubscriptionPriceId,
} from '../config';

interface BodyTypes {
  mode: string;
  quantity: number;
  client_reference_id: string;
  priceId: string;
  userId: string;
}

type FunctionTypes = {
  (userId: string): BodyTypes;
};

// スタンダードプランの一括支払いのセッションを取得
export const fetchStandardLumpBody: FunctionTypes = (userId) => {
  console.log('スタンダードプラン（一括）');
  const body = {
    mode: 'payment',
    quantity: 1,
    client_reference_id: stripeBasicLumpClientReferenceId,
    priceId: stripeBasicLumpPriceId,
    userId: userId,
  };
  return body;
};

// スタンダードプランの月額支払いのセッションを取得
export const fetchStandardInstallmentsBody: FunctionTypes = (userId) => {
  console.log('スタンダードプラン（月額）');
  const body = {
    mode: 'subscription',
    quantity: 1,
    client_reference_id: stripeBasicSubscriptionClientReferenceId,
    priceId: stripeBasicSubscriptionPriceId,
    userId: userId,
  };
  return body;
};

// ミニマムサポートプランの一括支払いのセッションを取得
export const fetchMinimumLumpBody: FunctionTypes = (userId) => {
  console.log('ミニマムサポートプラン（一括）');
  const body = {
    mode: 'payment',
    quantity: 1,
    client_reference_id: stripeMinimumSupportLumpClientReferenceId,
    priceId: stripeMinimumSupportLumpPriceId,
    userId: userId,
  };
  return body;
};

// ミニマムサポートプランの月額支払いのセッションを取得
export const fetchMinimumInstallmentsBody: FunctionTypes = (userId) => {
  console.log('ミニマムサポートプラン（月額）');
  const body = {
    mode: 'subscription',
    quantity: 1,
    client_reference_id: stripeMinimumSupportSubscriptionClientReferenceId,
    priceId: stripeMinimumSupportSubscriptionPriceId,
    userId: userId,
  };
  return body;
};

// フルサポートプランの一括支払いのセッションを取得
export const fetchFullLumpBody: FunctionTypes = (userId) => {
  console.log('フルサポートプラン（一括）');
  const body = {
    mode: 'payment',
    quantity: 1,
    client_reference_id: stripeFullSupportLumpClientReferenceId,
    priceId: stripeFullSupportLumpPriceId,
    userId: userId,
  };
  return body;
};

// フルサポートプランの月額支払いのセッションを取得
export const fetchFullInstallmentsBody: FunctionTypes = (userId) => {
  console.log('フルサポートプラン（月額）');
  const body = {
    mode: 'subscription',
    quantity: 1,
    client_reference_id: stripeFullSupportSubscriptionClientReferenceId,
    priceId: stripeFullSupportSubscriptionPriceId,
    userId: userId,
  };
  return body;
};

export const fetchSessionBody = (userId: string, sessionType: string) => {
  let body;
  switch (sessionType) {
    case 'fetchStandardLump':
      body = fetchStandardLumpBody(userId);
      break;
    case 'fetchStandardInstallments':
      body = fetchStandardInstallmentsBody(userId);
      break;
    case 'fetchMinimumLump':
      body = fetchMinimumLumpBody(userId);
      break;
    case 'fetchMinimumInstallments':
      body = fetchMinimumInstallmentsBody(userId);
      break;
    case 'fetchFullLumpBody':
      body = fetchFullLumpBody(userId);
      break;
    case 'fetchFullInstallments':
      body = fetchFullInstallmentsBody(userId);
      break;
  }
  return body;
};
