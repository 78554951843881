import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { useMediaQuery } from 'react-responsive';
import { Typography } from '@material-ui/core';
import CurriculumDetailStyle from './CurriculumDetail.style';

interface PropTypes {
  title: string;
  video: string;
  why: string;
  skills: { id: number; text: string }[];
  procedures: { id: number; text: string }[];
  outputs: { id: number; text: string }[];
  references: { id: number; title: string; link: string }[];
}

const CurriculumDetail: React.VFC<PropTypes> = ({
  title,
  video,
  why,
  skills,
  procedures,
  outputs,
  references,
}) => {
  const classes = CurriculumDetailStyle();
  const isTablet = useMediaQuery({
    query: '(max-device-width:900px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-device-width: 600px)',
  });

  const displayVideo = () => {
    if (isMobile) {
      return <ReactPlayer url={video} width={350} height={190.39} />;
    } else if (isTablet) {
      return <ReactPlayer url={video} width={600} height={326.38} />;
    } else {
      return <ReactPlayer url={video} width={900} height={506.25} />;
    }
  };
  const skillList = skills.map((skill) => {
    return (
      <li key={skill.id}>
        <Typography className={classes.text}>{skill.text}</Typography>
      </li>
    );
  });

  const procedureList = procedures.map((procedure) => {
    return (
      <li key={procedure.id}>
        <Typography className={classes.text}>{procedure.text}</Typography>
      </li>
    );
  });

  const outputList = outputs.map((output) => {
    return (
      <li key={output.id}>
        <Typography className={classes.text}>{output.text}</Typography>
      </li>
    );
  });

  const referenceList = references.map((reference) => {
    return (
      <li key={reference.id}>
        <a
          href={reference.link}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.text}
        >
          {reference.title}
        </a>
      </li>
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.video}>{displayVideo()}</div>
      <div className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
      </div>
      <div className={classes.why}>
        <Typography className={classes.subTitle}>
          なぜこの課題に取り組むか？
        </Typography>
        <Typography className={classes.text}>{why}</Typography>
      </div>
      <div className={classes.skills}>
        <Typography className={classes.subTitle}>
          以下のスキルが身につきます
        </Typography>
        <ul>{skillList}</ul>
      </div>
      <div className={classes.procedure}>
        <Typography className={classes.subTitle}>具体的な手順</Typography>
        <ol list-style-type="list-style-type: decimal">{procedureList}</ol>
      </div>
      <div className={classes.outputs}>
        <Typography className={classes.subTitle}>アウトプット項目</Typography>
        <ul>{outputList}</ul>
      </div>
      <div className={classes.references}>
        <Typography className={classes.subTitle}>参考サイト</Typography>
        <ul>{referenceList}</ul>
      </div>
      <div className={classes.report}>
        <Typography className={classes.subTitle}>課題に詰まったら</Typography>
        <Typography className={classes.text}>
          まずは1人で試行錯誤し、30分以内に解決できなかったら以下のテンプレートを用いてグループ内で質問しよう。
        </Typography>
        <Typography className={classes.template}>
          <p>
            タスクのゴール
            <br />
            (例)取得した画像を一覧表示できるようにしたい
          </p>
          <p>
            現状
            <br />
            (例)外部APIを用いて画像データの取得はできている
          </p>
          <p>
            何がわからないのか
            <br />
            (例)エラー文が理解できない、検索キーワードがわからない等
          </p>
          <p>
            自分が試した方法
            <br />
            (例)自分で調べてこれらのサイトを参考にしました
          </p>
          <p>
            リモートレポジトリURL
            <br />
            (例)https://github.com
          </p>
        </Typography>
      </div>
      <div className={classes.report}>
        <Typography className={classes.subTitle}>課題が完了したら</Typography>
        <Typography className={classes.text}>
          以下のテンプレートを用いてグループ内で報告しよう。
        </Typography>
        <Typography className={classes.template}>
          <p>課題番号：〇〇番</p>
          <p>アプリURL：作成したアプリのURL</p>
          <p>記事URL：Qiita等、アウトプット先のURL</p>
        </Typography>
      </div>
      {/* <Link to={paths.output} className={classes.link}>
        <Button variant="contained" className={classes.completeButton}>
          <Typography variant="body1" className={classes.completeText}>
            課題完了報告
          </Typography>
        </Button>
      </Link> */}
    </div>
  );
};

export default CurriculumDetail;
