import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import './index.css';
import App from './App';
import { store } from './stores/store';
import { Provider } from 'react-redux';
import awsconfig from './aws-exports';
import checkSession from './utils/checkSession';

import * as serviceWorker from './serviceWorker';

interface Window {
  isAuthed: CognitoUserSession | string | null;
}

declare let window: Window;

Amplify.configure(awsconfig);

(async function main() {
  window.isAuthed = null;
  try {
    window.isAuthed = await checkSession();
  } catch (err) {
    window.isAuthed = null;
    alert(err);
  }
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
