import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import CardComponent from '../../../components/card/Card';
import { selectMyProfile } from '../../../../stores/slices/userSlice';
import test1 from './images/test1.png';
import test2 from './images/test2.png';
import test3 from './images/test3.png';
import test4 from './images/test4.png';
import test5 from './images/test5.png';
import test6 from './images/test6.png';
import lock from './images/lock.png';
import CurriculumStyle from './Curriculum.style';

const Curriculum: React.VFC = () => {
  const classes = CurriculumStyle();
  const isTablet = useMediaQuery({
    query: '(max-device-width:1280px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-device-width:600px)',
  });

  const userData = useSelector(selectMyProfile);

  const { payment, customerId, testNumber } = userData;
  const allowTestNumber = testNumber;

  // テスト用
  // const payment = true;
  // const payment = false;
  // const customerId = 'aaaa';
  // const customerId = '';

  const displayCurriculum = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/1"
              testNum={1}
              thumbnail={test1}
              title="コンポーネント設計を体験しよう"
              payment={payment}
              allowTest={allowTestNumber >= 1 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/2"
              testNum={2}
              thumbnail={test2}
              title={
                allowTestNumber >= 2
                  ? 'React基礎：トレンド映画アプリの開発'
                  : '課題1を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 2 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/3"
              testNum={3}
              thumbnail={test3}
              title={
                allowTestNumber >= 3
                  ? 'Redux基礎：お天気アプリの開発'
                  : '課題2を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 3 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/4"
              testNum={4}
              thumbnail={test4}
              title={
                allowTestNumber >= 4
                  ? 'Amplify基礎：ブログアプリの開発'
                  : '課題3を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 4 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/5"
              testNum={5}
              thumbnail={test5}
              title={
                allowTestNumber >= 5
                  ? 'Amplify応用：SNSアプリの開発'
                  : '課題4を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 5 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/6"
              testNum={6}
              thumbnail={test6}
              title={
                allowTestNumber >= 6
                  ? 'オリジナルアプリの開発'
                  : '課題5を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 6 ? true : false}
            />
          </div>
        </React.Fragment>
      );
    } else if (isTablet) {
      return (
        <React.Fragment>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/1"
              testNum={1}
              thumbnail={test1}
              title="コンポーネント設計を体験しよう"
              payment={payment}
              allowTest={allowTestNumber >= 1 ? true : false}
            />
            <CardComponent
              link="/curriculum/2"
              testNum={2}
              thumbnail={test2}
              title={
                allowTestNumber >= 2
                  ? 'React基礎：トレンド映画アプリの開発'
                  : '課題1を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 2 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/3"
              testNum={3}
              thumbnail={test3}
              title={
                allowTestNumber >= 3
                  ? 'Redux基礎：お天気アプリの開発'
                  : '課題2を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 3 ? true : false}
            />
            <CardComponent
              link="/curriculum/4"
              testNum={4}
              thumbnail={test4}
              title={
                allowTestNumber >= 4
                  ? 'Amplify基礎：ブログアプリの開発'
                  : '課題3を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 4 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/5"
              testNum={5}
              thumbnail={test5}
              title={
                allowTestNumber >= 5
                  ? 'Amplify応用：SNSアプリの開発'
                  : '課題4を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 5 ? true : false}
            />
            <CardComponent
              link="/curriculum/6"
              testNum={6}
              thumbnail={test6}
              title={
                allowTestNumber >= 6
                  ? 'オリジナルアプリの開発'
                  : '課題5を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 6 ? true : false}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/1"
              testNum={1}
              thumbnail={test1}
              title="コンポーネント設計を体験しよう"
              payment={payment}
              allowTest={allowTestNumber >= 1 ? true : false}
            />
            <CardComponent
              link="/curriculum/2"
              testNum={2}
              thumbnail={test2}
              title={
                allowTestNumber >= 2
                  ? 'React基礎：トレンド映画アプリの開発'
                  : '課題1を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 2 ? true : false}
            />
            <CardComponent
              link="/curriculum/3"
              testNum={3}
              thumbnail={test3}
              title={
                allowTestNumber >= 3
                  ? 'Redux基礎：お天気アプリの開発'
                  : '課題2を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 3 ? true : false}
            />
          </div>
          <div className={classes.group}>
            <CardComponent
              link="/curriculum/4"
              testNum={4}
              thumbnail={test4}
              title={
                allowTestNumber >= 4
                  ? 'Amplify基礎：ブログアプリの開発'
                  : '課題3を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 4 ? true : false}
            />
            <CardComponent
              link="/curriculum/5"
              testNum={5}
              thumbnail={test5}
              title={
                allowTestNumber >= 5
                  ? 'Amplify応用：SNSアプリの開発'
                  : '課題4を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 5 ? true : false}
            />
            <CardComponent
              link="/curriculum/6"
              testNum={6}
              thumbnail={test6}
              title={
                allowTestNumber >= 6
                  ? 'オリジナルアプリの開発'
                  : '課題5を完了させてください'
              }
              payment={payment}
              allowTest={allowTestNumber >= 6 ? true : false}
            />
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <div className={classes.root}>
      {!payment ? (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>
              {customerId === 'noCheckOut'
                ? '決済処理を完了させてください'
                : 'ご登録いただいたカード情報で決済処理に失敗しております'}
            </AlertTitle>
            <strong>
              {customerId === 'noCheckOut'
                ? 'ヘッダーのカードアイコンをクリックし決済処理画面から決済情報の入力をお願いいたします'
                : 'ヘッダーのカードアイコンをクリックし決済処理画面から決済情報の更新をお願いいたします'}
            </strong>
          </Alert>
        </div>
      ) : null}
      {displayCurriculum()}
    </div>
  );
};

export default Curriculum;
