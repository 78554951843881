import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import thankImg from './thankImg.png';
import { selectMyProfile } from '../../../../stores/slices/userSlice';
import ThankStyle from './PaymentThank.style';

const PaymentThank: React.VFC = () => {
  const classes = ThankStyle();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const userData = useSelector(selectMyProfile);
  const customerId = userData.customerId;
  const redirectToCustomerPortal = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      const apiName = 'stripeAPI';
      const apiEndpoint = '/customer-portal';
      const body = {
        customerId: customerId,
      };
      const res = await API.post(apiName, apiEndpoint, { body });
      const url = res.url;
      window.location.href = url;
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setDisabled(false);
  };
  return (
    <div className={classes.root}>
      <div className={classes.alert}>
        <Alert severity="error">
          <AlertTitle>有料プランキャンセル時の注意</AlertTitle>
          <strong>
            有料プランのキャンセルに伴い、即時本サービスの課題が閲覧不可能になりますのでご注意ください。
          </strong>
        </Alert>
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography align="center" className={classes.titleText}>
            決済処理に成功しました
            <br />
            以下のボタンを入力し決済内容をご確認ください
            <Typography className={classes.subText}>
              ※一括支払いの方はメールより決済内容をご確認ください
            </Typography>
          </Typography>
          <Grid container spacing={2} className={classes.wrapper}>
            <Grid item xs={12}>
              <img src={thankImg} alt="Thank you" className={classes.image} />
            </Grid>
            <Button
              type="button"
              fullWidth
              onClick={() => {
                redirectToCustomerPortal();
              }}
              disabled={disabled}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading ? <CircularProgress size={28} /> : '決済内容を確認'}
            </Button>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default PaymentThank;
