import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const PlansStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: theme.spacing(5) },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    titleText: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h4.fontSize,
      },
    },
    toggleButtons: { display: 'flex', marginTop: theme.spacing(3) },
    toggleButton: {
      width: 350,
      height: 50,
      borderRadius: 8,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.h4.fontSize,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        width: 300,
        height: 40,
        borderRadius: 6,
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        width: 150,
        height: 30,
        borderRadius: 4,
        fontSize: theme.typography.body2.fontSize,
      },
    },
    toggleButtonGray: {
      width: 350,
      height: 50,
      borderRadius: 8,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.h4.fontSize,
      backgroundColor: theme.palette.bgGray.main,
      [theme.breakpoints.down('sm')]: {
        width: 300,
        height: 40,
        borderRadius: 6,
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        width: 150,
        height: 30,
        borderRadius: 4,
        fontSize: theme.typography.body2.fontSize,
      },
    },
    applyLimit:{},
    enrollmentFee: { marginTop: theme.spacing(5), textAlign: 'center' },
    enrollmentFeeText: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 700,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 700,
      },
    },
    enrollmentFeeHilight: {
      fontSize: theme.typography.h2.fontSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h4.fontSize,
      },
    },
  })
);
