import React from 'react';
import { Router } from 'react-router-dom';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ThemeProvider } from '@material-ui/core';
import Routes from './routes/Routes';
import history from './history';
import theme from './styles/theme';
import AppStyle from './App.style';

interface Window {
  isAuthed: CognitoUserSession | string | null;
}

declare let window: Window;

const App: React.VFC = () => {
  const classes = AppStyle();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Router history={history}>
          <Routes token={window.isAuthed} />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
