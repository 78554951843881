import React from 'react';
import CurriculumDetail from '../curriculumDetail/CurriculumDetail';
import MonthlytestStyle from './MonthlyTest.style';

interface PropTypes {
  text: {
    title: string;
    video: string;
    why: string;
    skills: { id: number; text: string }[];
    procedures: { id: number; text: string }[];
    outputs: { id: number; text: string }[];
    references: { id: number; title: string; link: string }[];
  };
}

const MonthlyTest: React.VFC<PropTypes> = ({ text }) => {
  const classes = MonthlytestStyle();
  return (
    <div className={classes.root}>
      <CurriculumDetail
        title={text.title}
        video={text.video}
        why={text.why}
        skills={text.skills}
        outputs={text.outputs}
        procedures={text.procedures}
        references={text.references}
      />
    </div>
  );
};

export default MonthlyTest;
