const paths = {
  // beforeRoutes
  landing: '/',
  userAuth: {
    signUp: '/signup',
    signIn: '/signin',
    confirmSignUp: '/confirm-signup',
    thank: '/signup-thank',
  },
  plan: {
    main: '/plans',
  },
  // afterRoutes
  payment: { main: '/payment', thank: '/payment/thank', edit: '/payment/edit' },
  curriculum: {
    list: '/curriculum',
    detail: '/curriculum/:curriculumId',
  },
  output: '/output',
  edit: { profile: '/edit/profile' },
  // 共通
  legal: {
    terms: '/terms',
    privacy: '/privacy',
    tokutei: '/tokutei',
  },
  contact: '/contact',
};

export default paths;
