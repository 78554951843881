import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const checkSession = async (): Promise<CognitoUserSession | null> => {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (err) {
    return err;
  }
};

export default checkSession;
