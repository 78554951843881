import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const PaymentUpdateStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: theme.spacing(5) },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    titleText: { fontSize: theme.typography.h4.fontSize, fontWeight: 500 },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginBottom: theme.spacing(8),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: '12px 16px',
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 600,
    },
  })
);
