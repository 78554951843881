import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Typography } from '@material-ui/core';
// import { Alert, AlertTitle } from '@material-ui/lab';
import DisplayVideo from '../../../components/displayVideo/DisplayVideo';
import introImg from './images/intro.png';
import problemImg from './images/problem.png';
import affinityImg1 from './images/affinity1.png';
import affinityImg2 from './images/affinity2.png';
import affinityImg3 from './images/affinity3.png';
import targetImg from './images/target.png';
import selfImg from './images/self.png';
import outputImg from './images/output.png';
import applyImg from './images/apply.png';
import lessonStartImg from './images/lessonStart.png';
import clearImg from './images/clear.png';
import onlyOneImg from './images/onlyOne.png';
import saitoSan from './images/saitosan.png';
import himSan from './images/himsan.png';
import yoshiwoSan from './images/yoshiwosan.jpg';
import paths from '../../../../config/paths';
import LandingStyle from './Landing.style';

const LandingMobile: React.VFC = () => {
  const classes = LandingStyle();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {/* 第二期生の募集が完了したらコメントアウトを解除する */}
        {/* <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>重要なお知らせ</AlertTitle>
            <strong>
              ただいま「あべちゃんのフロントエンド塾第一期生の募集」は終了しております。第二期生の募集は2021年8月以降を想定しております。
            </strong>
          </Alert>
        </div> */}
        <div className={classes.whiteContent}>
          <div className={classes.intro}>
            <div>
              <img src={introImg} alt="introImg" className={classes.largeImg} />
            </div>
            <div>
              <Typography
                align="center"
                style={{ marginBottom: 0 }}
                className={classes.whiteContentTitle}
              >
                駆け出しエンジニアはここで卒業
              </Typography>
              <Typography
                align="center"
                style={{ marginBottom: 0 }}
                className={classes.whiteContentTitle}
              >
                社会で活躍するエンジニアになるための
              </Typography>
              <Typography
                align="center"
                style={{ marginBottom: 0 }}
                className={classes.whiteContentTitle}
              >
                フロントエンドに特化した学習塾
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.purpleContent}>
          <div className={classes.problem}>
            <Typography className={classes.purpleContentTitle}>
              このような悩みを抱えていませんか？
            </Typography>
            <div className={classes.wrapper}>
              <div>
                <img
                  src={problemImg}
                  alt="problemImg"
                  className={classes.mediumImg}
                />
              </div>
              <div>
                <Typography
                  align="center"
                  className={classes.purpleContentText}
                >
                  プログラミングの勉強を始めて数ヶ月
                </Typography>
                <Typography
                  align="center"
                  className={classes.purpleContentText}
                >
                  動画教材をなぞることでアプリを作れるようになったが
                </Typography>
                <Typography
                  align="center"
                  className={classes.purpleContentText}
                >
                  自分で1から作るとなると何をしてよいかわからない...
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.whiteContent}>
          <div className={classes.solution}>
            <Typography className={classes.whiteContentTitle}>
              私も駆け出しの時は同じ悩みを抱えてました
            </Typography>
            <div className={classes.pointWrapper}>
              <div className={classes.point}>
                <img
                  src={affinityImg1}
                  alt="affinityImg1"
                  className={classes.smallImg}
                />
                <Typography className={classes.whiteContentText}>
                  自走力が身につかない
                </Typography>
                <Typography className={classes.whiteContentSubText}>
                  動画教材を繰り返しなぞることでなんとなくアプリはできるが、いざ自分1人でアプリを作ろうと思うと手が動かず時間だけが過ぎ去ってしまう...
                </Typography>
              </div>
              <div className={classes.point}>
                <img
                  src={affinityImg2}
                  alt="affinityImg2"
                  className={classes.smallImg}
                />
                <Typography className={classes.whiteContentText}>
                  求められているスキルがわからない
                </Typography>
                <Typography className={classes.whiteContentSubText}>
                  どの言語をどの程度学べば採用されるかわからず、ひたすら勉強の毎日。ゴールのわからないマラソンを走っているようで勉強が続かない...
                </Typography>
              </div>
              <div className={classes.point}>
                <img
                  src={affinityImg3}
                  alt="affinityImg3"
                  className={classes.smallImg}
                />
                <Typography className={classes.whiteContentText}>
                  質問の方法がわからない
                </Typography>
                <Typography className={classes.whiteContentSubText}>
                  わからないことに関して質問をしたいが、適切な質問方法がわからず適当な質問をするとエンジニアの人に怒られないか不安になる...
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.purpleContent}>
          <div className={classes.features}>
            <Typography align="center" className={classes.purpleContentTitle}>
              その悩みを解決したいという思いから
              <br />
              本塾を立ち上げました
            </Typography>
            <div className={classes.pointWrapper}>
              <div className={classes.point}>
                <img src={selfImg} alt="selfImg" className={classes.smallImg} />
                <Typography className={classes.purpleContentText}>
                  自走力が身につく
                </Typography>
                <Typography className={classes.purpleContentSubText}>
                  講師から提示されるのはスタートとゴールのみ。生徒同士で議論をし自分たちの力でゴールに辿り着くことでエンジニアとしての自走力を磨きます。
                </Typography>
              </div>
              <div className={classes.point}>
                <img
                  src={targetImg}
                  alt="targetImg"
                  className={classes.smallImg}
                />
                <Typography className={classes.purpleContentText}>
                  ゴールが明確になる
                </Typography>
                <Typography className={classes.purpleContentSubText}>
                  実務未経験エンジニアには最低限このスキルを身につけて欲しいという現場の声を参考に課題を設計。課題をこなすことで自然と必要なスキルが習得できます。
                </Typography>
              </div>
              <div className={classes.point}>
                <img
                  src={outputImg}
                  alt="outputImg"
                  className={classes.smallImg}
                />
                <Typography className={classes.purpleContentText}>
                  適切な質問の方法がわかる
                </Typography>
                <Typography className={classes.purpleContentSubText}>
                  自身の経験をもとに、適切な質問のテンプレートをご用意。そのテンプレートをベースに質問を繰り返し行うことで、徐々に質問力を高めていくことができます。
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.whiteContent}>
          <div className={classes.flow}>
            <Typography align="center" className={classes.whiteContentTitle}>
              自走力を確実に身につけるための
              <br />
              学習カリキュラムを一部ご紹介
            </Typography>
            <div className={classes.video}>
              <DisplayVideo url="https://youtu.be/VgOqQFOVrWc" />
            </div>
            <Typography className={classes.whiteContentText}>
              1. コンポーネント設計を体験しよう
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.purpleContent}>
          <div className={classes.flow}>
            <Typography align="center" className={classes.purpleContentTitle}>
              あべちゃんのフロントエンド塾
              <br />
              における受講の流れ
            </Typography>
            <div className={classes.pointWrapper}>
              <div className={classes.point}>
                <img
                  src={applyImg}
                  alt="applyImg"
                  className={classes.smallImg}
                />
                <Typography className={classes.purpleContentText}>
                  １．申し込み
                </Typography>
                <Typography className={classes.purpleContentSubText}>
                  塾生限定のSlackへとご招待。生徒同士で教え合いながら、時には講師に教わりながら、テンプレートに従い、相手が回答しやすい適切な質問をするスキルを高めていただきます。
                </Typography>
              </div>
              <div className={classes.point}>
                <img
                  src={lessonStartImg}
                  alt="lessonStartImg"
                  className={classes.smallImg}
                />
                <Typography className={classes.purpleContentText}>
                  ２．受講開始
                </Typography>
                <Typography className={classes.purpleContentSubText}>
                  講師から解くべき課題が与えられます。見本のコードを見ずに、独自の調査や周囲への質問を繰り返しながらアプリを完成させる経験を積むことで、自走力を高めていただきます。
                </Typography>
              </div>
              <div className={classes.point}>
                <img
                  src={clearImg}
                  alt="clearImg"
                  className={classes.smallImg}
                />
                <Typography className={classes.purpleContentText}>
                  ３．課題クリア
                </Typography>
                <Typography className={classes.purpleContentSubText}>
                  アプリ開発の過程をQiita等に言語化することで課題クリアとなります。記事作成を通してエンジニアの必須スキルであるドキュメント作成スキルを身につけていただきます。
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.whiteContent}>
          <div className={classes.messages}>
            <Typography className={classes.whiteContentTitle}>
              入塾頂いた生徒様からのご感想
            </Typography>
            <div className={classes.pointWrapper}>
              <div className={classes.point}>
                <Avatar
                  src={saitoSan}
                  alt="斎藤さん"
                  className={classes.avatar}
                />
                <Typography
                  className={classes.whiteContentText}
                  style={{ marginBottom: 0 }}
                >
                  斎藤さん
                </Typography>
                <Typography className={classes.whiteContentText}>
                  Web系自社開発企業へ転職成功
                </Typography>
                <Typography className={classes.whiteContentSubText}>
                  エンジニアに必要な自走力を身につけることができ、最適なアウトプットを自然とできるようになりました！
                </Typography>
              </div>
              <div className={classes.point}>
                <Avatar src={himSan} alt="HiM" className={classes.avatar} />
                <Typography
                  className={classes.whiteContentText}
                  style={{ marginBottom: 0 }}
                >
                  HiMさん
                </Typography>
                <Typography className={classes.whiteContentText}>
                  ITコンサル企業へ転職成功
                </Typography>
                <Typography className={classes.whiteContentSubText}>
                  課題をこなす中で調べる力と理解する力が確実に増していき、いつの間にか自走力が身についていました。
                </Typography>
              </div>
              <div className={classes.point}>
                <Avatar
                  src={yoshiwoSan}
                  alt="よしをさん"
                  className={classes.avatar}
                />
                <Typography
                  className={classes.whiteContentText}
                  style={{ marginBottom: 0 }}
                >
                  よしをさん
                </Typography>
                <Typography className={classes.whiteContentText}>
                  ドラマーから転職成功
                </Typography>
                <Typography className={classes.whiteContentSubText}>
                  30代未経験からフロントエンドエンジニアに転職できました！あべちゃんの様々なアドバイスに感謝です✨
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 第二期生の募集が完了したらコメントアウトする */}
      <div className={classes.content}>
        <div className={classes.purpleContent}>
          <div className={classes.action}>
            <Typography
              style={{ marginBottom: 32 }}
              className={classes.purpleContentTitle}
            >
              さあ！さっそく始めてみよう！
            </Typography>
            <div className={classes.wrapper}>
              <div>
                <img
                  src={onlyOneImg}
                  alt="onlyOneImg"
                  className={classes.mediumImg}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  align="center"
                  style={{ marginBottom: 16 }}
                  className={classes.purpleContentText}
                >
                  皆様と本塾内でお会いできることを
                  <br />
                  心より楽しみにしております
                </Typography>
                <Link to={paths.userAuth.signUp} className={classes.buttonLink}>
                  <Button
                    variant="contained"
                    className={classes.registerButton}
                  >
                    <Typography className={classes.registerText}>
                      入塾する
                    </Typography>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.whiteContent}>
          <div className={classes.qa}>
            <Typography className={classes.whiteContentTitle}>
              よくあるご質問
            </Typography>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  受講における必要条件はありますか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  特別な条件はございませんが、私のYoutubeチャンネルにおいて
                  <a
                    href="https://www.youtube.com/watch?v=ojesAXHKf7U&list=PLtbitg0evatiDrOncn51ku2mD1LGve-_h"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    React入門講座
                  </a>
                  、
                  <a
                    href="https://www.youtube.com/watch?v=Lmtr8hmXvj4&list=PLtbitg0evatjxN_BhyJO124bV2eDoJumf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Redux入門講座
                  </a>
                  、
                  <a
                    href="https://www.youtube.com/watch?v=yQz2gSQiOZA&list=PLtbitg0evatg24WZm9Q8wTIP-We2V0OpZ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ReduxToolkit入門講座
                  </a>
                  の3講座を受講後の方がスムーズに課題に取り組めるサービス設計になっております。上記講座が未受講でも同等の実力があるとご判断できればぜひご受講ください。
                </Typography>
              </div>
            </div>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  パソコンはWindows、Macのどちらでも大丈夫でしょうか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  Mac（macOS Catalina バージョン10.15
                  以降）を前提として課題を設計しておりますのでMacを購入してからの受講を強くおすすめいたします。
                </Typography>
              </div>
            </div>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  卒業後どのようなエンジニアになれますか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  技術面ではTypeScript、React、ReduxToolkit、AWS
                  Amplifyを用いてサーバレスアプリケーションを１人で実装できるエンジニアを目指します。また、卒業後にエンジニアとして一人立ちをするために必要な粘り強い自走力、周囲の方と適切なコミュニケーションを取りながら問題を解決していく能力が身に付きます。
                </Typography>
              </div>
            </div>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  どの程度の期間で卒業できますか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  1ヶ月に1つのペースで課題を解放いたします。課題は合計で6つ、半年間での卒業を前提にプログラムを組んでおります。
                  1ヶ月に1つのペースで課題を解放する理由としては以下2点でございます。
                  <ul>
                    <li>
                      アプリの開発だけではなく、アプリ開発において学んだことをしっかりとアウトプットして欲しいから
                    </li>
                    <li>
                      自走力を高めるという観点から、今の自分には何が足りないか、少しずつで良いので自分で学習すべき内容を見つけ、実際に学習に移す癖を身につけて頂きたいから
                    </li>
                  </ul>
                </Typography>
              </div>
            </div>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  料金はかかりますか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  入塾金33,000円（税込み）と各プランに応じて月々の料金を頂戴いたします（料金詳細は
                  <Link to={paths.plan.main}>プラン一覧</Link>
                  よりご確認ください）。一般的なプログラミングスクールでは、半年間学ぶだけで受講料は60万円ほどかかります。月換算にすると月10万円ほどにもなり、受講していて「自分に合わない」と分かった時には遅く、受講を後悔したという話を聞いております。当塾では月々9,800円(税込)からサービスを提供しており、半年で卒業という設計ですので、大手プログラミングスクールの1ヶ月分以下の料金で半年間学ぶことができ、着実にエンジニア転職に必要なスキルを身につけることができます。
                </Typography>
              </div>
            </div>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  講師に直接質問はできますか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  お申し込みのプランにより講師からのサポート体制は異なります。スタンダードプランでは生徒様同士で教え合う
                  <a
                    href="https://jinjibu.jp/keyword/detl/1168/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ピアラーニング
                  </a>
                  を中心に学習を進めていただきます（生徒様同士の議論でエラーが解決しない場合は2-3日ほど様子を見てから講師がサポートいたします）。ミニマムサポートプラン以上では質問数無制限で直接講師に質問ができ、24h以内に回答を受け取ることができます。
                  （サポート体制の詳細は
                  <Link to={paths.plan.main}>プラン一覧</Link>
                  よりご確認ください）
                </Typography>
              </div>
            </div>
            <div className={classes.qaWrapper}>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>Q.</Typography>
                <Typography className={classes.qaText}>
                  転職サポートはありますか？
                </Typography>
              </div>
              <div className={classes.qaTextWrapper}>
                <Typography className={classes.qaIcon}>A.</Typography>
                <Typography className={classes.qaText}>
                  申し訳ありませんが現時点では転職サポートのサービスはございません。現在、本塾に協賛いただけるパートナー企業様を探しておりますので、将来的には転職先をご紹介できる可能性がございます。
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingMobile;
