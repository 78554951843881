import { createStyles, makeStyles, Theme } from '@material-ui/core';

const RoutesStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', flexDirection: 'column', minHeight: '100vh' },
    main: {
      marginTop: 100,
      [theme.breakpoints.down('sm')]: {
        marginTop: 90,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 60,
      },
    },
    footer: { marginTop: 'auto' },
  })
);

export default RoutesStyle;
