import React from 'react';
import { Typography } from '@material-ui/core';
import TokuteiStyle from './Tokutei.style';

const Tokutei: React.VFC = () => {
  const classes = TokuteiStyle();
  return (
    <div className={classes.content}>
      <div className={classes.whiteContent}>
        <div className={classes.tokutei}>
          <Typography className={classes.whiteContentTitle}>
            特定商取引に関する表記
          </Typography>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                「特定商取引に関する法律」第11条（通信販売についての広告）等に基づき以下に明示いたします。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                販売業者
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>阿部直也</Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                メールアドレス
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                abechanfront@gmail.com
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                お問い合わせ先
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                email: abechanfront@gmail.com
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                フルネーム（姓、名）、お問い合わせ内容をご記入の上、上記連絡先にご送信ください。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                販売価格
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                各商品に表記された価格に準じます。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                販売価格以外の料金
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                商品価格に税金も含まれておりますので販売価格以外の料金はかかりません。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                注文方法
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                インターネット
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                支払方法
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                クレジットカード支払いによる月額課金方式（前払い）になります。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                支払時期
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                各社クレジットカードによる決済時期によります。ご利用されるカード会社までお問い合わせください。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                サービスの提供時期
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                代金決済手続きの完了確認後直ぐにご利用頂けます。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                返金・キャンセルについて
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                サービスの特性上、返金・キャンセルは受け付けておりません。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                不良品の取扱条件
              </Typography>
            </div>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiText}>
                商品がご利用できない場合は、お問い合わせ先よりお問い合わせ下さい。
              </Typography>
            </div>
          </div>
          <div className={classes.tokuteiWrapper}>
            <div className={classes.tokuteiTextWrapper}>
              <Typography className={classes.tokuteiCategoryTitle}>
                動作環境
              </Typography>
            </div>
            <div className={classes.envTextWrapper}>
              <Typography className={classes.tokuteiIcon}>・</Typography>
              <Typography className={classes.tokuteiText}>
                macOS Catalina バージョン10.15 以降を強く推奨
              </Typography>
            </div>
            <div className={classes.envTextWrapper}>
              <Typography className={classes.tokuteiIcon}>・</Typography>
              <Typography className={classes.tokuteiText}>
                Webブラウザ：GoogleChrome（最新版）{' '}
              </Typography>
            </div>
            <div className={classes.envTextWrapper}>
              <Typography className={classes.tokuteiIcon}>・</Typography>
              <Typography className={classes.tokuteiText}>
                CPU：上記OSが推奨する稼働環境に準じます。
              </Typography>
            </div>
            <div className={classes.envTextWrapper}>
              <Typography className={classes.tokuteiIcon}>・</Typography>
              <Typography className={classes.tokuteiText}>
                メモリ：8GB以上
              </Typography>
            </div>
            <div className={classes.envTextWrapper}>
              <Typography className={classes.tokuteiIcon}>・</Typography>
              <Typography className={classes.tokuteiText}>
                ハードディスクの空き容量：10GB以上
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokutei;
