import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import CardStyle from './Card.style';

interface PropTypes {
  link: string;
  testNum: number;
  thumbnail: string;
  title: string;
  payment: boolean;
  allowTest: boolean;
}

const CardComponent: React.VFC<PropTypes> = ({
  link,
  testNum,
  thumbnail,
  title,
  payment,
  allowTest,
}) => {
  const classes = CardStyle();
  const displayCard = () => {
    if (payment) {
      if (!allowTest) {
        return (
          <Card className={classes.root}>
            <CardHeader
              title={`課題その${testNum}`}
              classes={{ title: classes.cardHeader }}
            />
            <CardMedia
              className={classes.media}
              image={thumbnail}
              title="Thumbnail"
            />
            <CardContent>
              <Typography className={classes.title}>
                {`課題その${testNum - 1}を完了してください`}
              </Typography>
            </CardContent>
          </Card>
        );
      } else {
        return (
          <Link to={link} className={classes.link}>
            <Card className={classes.root}>
              <CardHeader
                title={`課題その${testNum}`}
                classes={{ title: classes.cardHeader }}
              />
              <CardMedia
                className={classes.media}
                image={thumbnail}
                title="Thumbnail"
              />
              <CardContent>
                <Typography className={classes.title}>{title}</Typography>
              </CardContent>
            </Card>
          </Link>
        );
      }
    } else {
      return (
        <Card className={classes.root}>
          <CardHeader
            title={`課題その${testNum}`}
            classes={{ title: classes.cardHeader }}
          />
          <CardMedia
            className={classes.media}
            image={thumbnail}
            title="Thumbnail"
          />
          <CardContent>
            <Typography className={classes.title}>{title}</Typography>
          </CardContent>
        </Card>
      );
    }
  };
  return displayCard();
};

export default CardComponent;
