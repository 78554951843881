import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Route, Switch } from 'react-router-dom';
import Landing from '../../views/pages/beforeRoutes/landing/Landing';
import SignUp from '../../views/pages/beforeRoutes/signUp/SignUp';
import SignIn from '../../views/pages/beforeRoutes/signIn/SignIn';
import Confirm from '../../views/pages/beforeRoutes/confirm/Confirm';
import Thank from '../../views/pages/beforeRoutes/thank/Thank';
import paths from '../../config/paths';
import Privacy from '../../views/pages/legal/privacy/Privacy';
import Terms from '../../views/pages/legal/terms/Terms';
import LandingMobile from '../../views/pages/beforeRoutes/landing/LandingMobile';
import Tokutei from '../../views/pages/legal/tokutei/Tokutei';
import Plans from '../../views/pages/beforeRoutes/plans/Plans';

const BeforeRoutes: React.VFC = () => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 600px)',
  });
  return (
    <Switch>
      {/* 第二期生の募集が完了したらSignUpをコメントアウトする */}
      <Route exact path={paths.userAuth.signUp} component={SignUp} />
      <Route exact path={paths.userAuth.confirmSignUp} component={Confirm} />
      <Route exact path={paths.userAuth.thank} component={Thank} />
      <Route exact path={paths.userAuth.signIn} component={SignIn} />
      <Route
        exact
        path={paths.landing}
        component={isMobile ? LandingMobile : Landing}
      />
      <Route exact path={paths.legal.terms} component={Terms} />
      <Route exact path={paths.legal.tokutei} component={Tokutei} />
      <Route exact path={paths.legal.privacy} component={Privacy} />
      <Route exact path={paths.plan.main} component={Plans} />
    </Switch>
  );
};

export default BeforeRoutes;
