import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import FooterStyle from './Footer.style';
import paths from '../../../config/paths';

const Footer: React.VFC = () => {
  const classes = FooterStyle();
  const currentYear = new Date().getFullYear();
  const isMobile = useMediaQuery({
    query: '(max-device-width: 600px)',
  });

  return (
    <footer className={classes.root}>
      <div className={classes.legalWrapper}>
        {isMobile ? (
          // <React.Fragment>
          //   <p className={classes.pTag}>
          //     <Link to={paths.legal.terms} className={classes.link}>
          //       利用規約
          //     </Link>
          //     <Link to={paths.contact} className={classes.link}>
          //       お問い合わせ
          //     </Link>
          //   </p>
          //   <p className={classes.pTag}>
          //     <Link to={paths.legal.tokutei} className={classes.link}>
          //       特定商取引に関する表記
          //     </Link>
          //     <Link to={paths.legal.privacy} className={classes.link}>
          //       プライバシーポリシー
          //     </Link>
          //   </p>
          // </React.Fragment>
          <React.Fragment>
            <div className={classes.mobileLegalWrapper}>
              <p>
                <Link to={paths.legal.terms} className={classes.link}>
                  利用規約
                </Link>
                <Link
                  to={paths.legal.privacy}
                  style={{ marginLeft: 56 }}
                  className={classes.link}
                >
                  プライバシーポリシー
                </Link>
              </p>
              <p>
                <Link to={paths.legal.tokutei} className={classes.link}>
                  特定商取引に関する表記
                </Link>
              </p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Link to={paths.legal.terms} className={classes.link}>
              利用規約
            </Link>
            <Link to={paths.legal.privacy} className={classes.link}>
              プライバシーポリシー
            </Link>
            <Link to={paths.legal.tokutei} className={classes.link}>
              特定商取引に関する表記
            </Link>
            {/* <Link to={paths.contact} className={classes.link}>
              お問い合わせ
            </Link> */}
          </React.Fragment>
        )}
      </div>
      <Typography variant="body2" align="center">
        {`©︎${currentYear} あべちゃんのフロントエンド塾`}
      </Typography>
    </footer>
  );
};

export default Footer;
