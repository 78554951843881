export const stripePublicApiKey: string =
  process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || '';

// ベーシックプラン
export const stripeBasicSubscriptionPriceId: string =
  process.env.REACT_APP_STRIPE_BASIC_SUBSCRIPTION_PRICE_ID || '';
export const stripeBasicSubscriptionClientReferenceId: string =
  process.env.REACT_APP_STRIPE_BASIC_SUBSCRIPTION_CLIENT_REFERENCE_ID || '';
export const stripeBasicLumpPriceId: string =
  process.env.REACT_APP_STRIPE_BASIC_LUMP_PRICE_ID || '';
export const stripeBasicLumpClientReferenceId: string =
  process.env.REACT_APP_STRIPE_BASIC_LUMP_CLIENT_REFERENCE_ID || '';

// ミニマムサポートプラン
export const stripeMinimumSupportSubscriptionPriceId: string =
  process.env.REACT_APP_STRIPE_MINIMUM_SUPPORT_SUBSCRIPTION_PRICE_ID || '';
export const stripeMinimumSupportSubscriptionClientReferenceId: string =
  process.env
    .REACT_APP_STRIPE_MINIMUM_SUPPORT_SUBSCRIPTION_CLIENT_REFERENCE_ID || '';
export const stripeMinimumSupportLumpPriceId: string =
  process.env.REACT_APP_STRIPE_MINIMUM_SUPPORT_LUMP_PRICE_ID || '';
export const stripeMinimumSupportLumpClientReferenceId: string =
  process.env.REACT_APP_STRIPE_MINIMUM_SUPPORT_LUMP_CLIENT_REFERENCE_ID || '';

// フルサポートプラン
export const stripeFullSupportSubscriptionPriceId: string =
  process.env.REACT_APP_STRIPE_FULL_SUPPORT_SUBSCRIPTION_PRICE_ID || '';
export const stripeFullSupportSubscriptionClientReferenceId: string =
  process.env.REACT_APP_STRIPE_FULL_SUPPORT_SUBSCRIPTION_CLIENT_REFERENCE_ID ||
  '';
export const stripeFullSupportLumpPriceId: string =
  process.env.REACT_APP_STRIPE_FULL_SUPPORT_LUMP_PRICE_ID || '';
export const stripeFullSupportLumpClientReferenceId: string =
  process.env.REACT_APP_STRIPE_FULL_SUPPORT_LUMP_CLIENT_REFERENCE_ID || '';
