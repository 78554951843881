import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import '@stripe/stripe-js';
import { Auth } from 'aws-amplify';
import Curriculum from '../../views/pages/afterRoutes/curriculum/Curriculum';
import paths from '../../config/paths';
import { fetchUserApi } from '../../stores/slices/userSlice';
import { AppDispatch } from '../../stores/store';
import AfterRoutesStyle from './AfterRoutes.style';
import FirstTest from '../../views/pages/afterRoutes/curriculum/1stTest/FirstTest';
import SecondTest from '../../views/pages/afterRoutes/curriculum/2ndTest/SecondTest';
import ThirdTest from '../../views/pages/afterRoutes/curriculum/3rdTest/ThirdTest';
import FourthTest from '../../views/pages/afterRoutes/curriculum/4thTest/FourthTest';
import FifthTest from '../../views/pages/afterRoutes/curriculum/5thTest/FifthTest';
import SixthTest from '../../views/pages/afterRoutes/curriculum/6thTest/SixthTest';
// import Output from '../../views/pages/afterRoutes/output/Output';
import Payment from '../../views/pages/afterRoutes/payment/Payment';
import PaymentThank from '../../views/pages/afterRoutes/paymentThank/PaymentThank';
import PaymentUpdate from '../../views/pages/afterRoutes/paymentUpdate/PaymentUpdate';
import Terms from '../../views/pages/legal/terms/Terms';
import Tokutei from '../../views/pages/legal/tokutei/Tokutei';
import Privacy from '../../views/pages/legal/privacy/Privacy';

const AfterRoutes: React.VFC = () => {
  const classes = AfterRoutesStyle();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const fetchProfile = async () => {
      const { attributes } = await Auth.currentAuthenticatedUser();
      await dispatch(fetchUserApi(attributes.sub));
    };
    fetchProfile();
  }, []);

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path={'/curriculum/1'} component={FirstTest} />
        <Route exact path={'/curriculum/2'} component={SecondTest} />
        <Route exact path={'/curriculum/3'} component={ThirdTest} />
        <Route exact path={'/curriculum/4'} component={FourthTest} />
        <Route exact path={'/curriculum/5'} component={FifthTest} />
        <Route exact path={'/curriculum/6'} component={SixthTest} />
        <Route exact path={paths.curriculum.list} component={Curriculum} />
        {/* 後々追加する */}
        {/* <Route exact path={paths.output} component={Output} /> */}
        <Route exact path={paths.payment.main} component={Payment} />
        <Route exact path={paths.payment.edit} component={PaymentUpdate} />
        <Route exact path={paths.payment.thank} component={PaymentThank} />
        {/* 必要になったら追加する */}
        {/* <Route exact path={paths.edit.profile} component={EditProfile} /> */}
        <Route exact path={paths.legal.terms} component={Terms} />
        <Route exact path={paths.legal.tokutei} component={Tokutei} />
        <Route exact path={paths.legal.privacy} component={Privacy} />
        <Redirect to={paths.curriculum.list} />
      </Switch>
    </div>
  );
};

export default AfterRoutes;
