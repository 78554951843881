import { createStyles, makeStyles, Theme } from '@material-ui/core';

const CurriculumDetailStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 900,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: { maxWidth: 600 },
      [theme.breakpoints.down('xs')]: { maxWidth: 350 },
    },
    title: { width: '100%', marginTop: theme.spacing(3) },
    titleText: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h4.fontSize,
      },
    },
    subTitle: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 600,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    text: {
      fontSize: theme.typography.h4.fontSize,
      textAlign: 'justify',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    video: {},
    why: {
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    skills: {
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    procedure: {
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    references: {
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    outputs: {
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    completeButton: {
      width: 200,
      height: 48,
      backgroundColor: theme.palette.primary.main,
      margin: '24px 0',
      borderRadius: 10,
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    completeText: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    link: { textDecoration: 'none' },
    report: {
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: { margin: '16px 0' },
      [theme.breakpoints.down('xs')]: { margin: '8px 0' },
    },
    template: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: '#797979',
      fontSize: theme.typography.h4.fontSize,
      color: theme.palette.common.white,
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        fontSize: theme.typography.h4.fontSize,
        borderRadius: '10px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        fontSize: theme.typography.h5.fontSize,
        borderRadius: '7px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
        fontSize: theme.typography.h6.fontSize,
        borderRadius: '5px',
      },
    },
  })
);

export default CurriculumDetailStyle;
