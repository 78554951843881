import { createStyles, makeStyles, Theme } from '@material-ui/core';

const HeaderStyle = makeStyles((theme: Theme) =>
  createStyles({
    appBarRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 100,
      backgroundColor: theme.palette.common.white,
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        height: 90,
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        height: 60,
        paddingRight: theme.spacing(0),
      },
    },
    toolBarRoot: {
      width: '100%',
      maxWidth: 1200,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 960,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 600,
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
      },
    },
    logo: {
      width: 90,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: 70,
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        width: 30,
        marginRight: theme.spacing(1),
      },
    },
    logoText: {
      fontFamily: 'Helvetica',
      fontSize: theme.typography.h2.fontSize,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    authWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    },
    button: { marginRight: theme.spacing(2), marginLeft: theme.spacing(2) },
    registerButton: {
      width: 130,
      height: 48,
      fontSize: theme.typography.h4.fontSize,
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
      borderRadius: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
        width: 100,
        height: 42,
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: 7,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
        width: 78,
        height: 30,
        marginLeft: theme.spacing(0),
        borderRadius: 5,
      },
    },
    buttonLink: {
      textDecoration: 'none',
    },
    loginText: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    registerText: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  })
);

export default HeaderStyle;
