import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMyProfile } from '../../../../../stores/slices/userSlice';
import history from '../../../../../history';
import paths from '../../../../../config/paths';
import MonthlyTest from '../../../../components/monthlyTest/MonthlyTest';
import text from './text.json';

const ThirdTest: React.VFC = () => {
  const userData = useSelector(selectMyProfile);
  const { payment, testNumber } = userData;

  useEffect(() => {
    if (!payment) {
      history.push(paths.curriculum.list);
    } else {
      if (testNumber < 3) {
        history.push(paths.curriculum.list);
      } else {
        null;
      }
    }
  }, [testNumber, payment]);

  return <MonthlyTest text={text} />;
};

export default ThirdTest;
