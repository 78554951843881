import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Link } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PaymentIcon from '@material-ui/icons/Payment';
import { selectMyProfile } from '../../../stores/slices/userSlice';
import { signOut } from '../../pages/beforeRoutes/utils/userAuth';
import paths from '../../../config/paths';
import logo from './images/logo.png';
import HeaderStyle from './Header.style';

interface Window {
  isAuthed: CognitoUserSession | string | null;
}

declare let window: Window;

const Header: React.VFC = () => {
  const classes = HeaderStyle();
  const userData = useSelector(selectMyProfile);
  const { customerId, payment } = userData;
  const cardIconLink = () => {
    if (payment) {
      return paths.payment.thank;
    } else {
      if (customerId === 'noCheckOut') {
        return paths.payment.main;
      } else {
        return paths.payment.edit;
      }
    }
  };
  return (
    <AppBar position="fixed" classes={{ root: classes.appBarRoot }}>
      <Toolbar classes={{ root: classes.toolBarRoot }}>
        <Link
          to={
            window.isAuthed === 'No current user'
              ? paths.landing
              : paths.curriculum.list
          }
          className={classes.link}
        >
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography className={classes.logoText}>
            あべちゃんのフロントエンド塾
          </Typography>
        </Link>
        <div className={classes.authWrapper}>
          {window.isAuthed === 'No current user' ? (
            <React.Fragment>
              <Link to={paths.userAuth.signIn} className={classes.link}>
                <Button color="inherit">
                  <Typography className={classes.loginText}>
                    ログイン
                  </Typography>
                </Button>
              </Link>
              {/* 第二期生の募集が完了したらコメントアウトする */}
              <Link to={paths.userAuth.signUp} className={classes.buttonLink}>
                <Button variant="contained" className={classes.registerButton}>
                  <Typography className={classes.registerText}>
                    入塾する
                  </Typography>
                </Button>
              </Link>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Link to={paths.curriculum.list}>
                <Button color="inherit">
                  <HomeIcon fontSize="large" />
                </Button>
              </Link>
              <Link to={paths.edit.profile}>
                <Button color="inherit">
                  <PersonIcon fontSize="large" />
                </Button>
              </Link> */}
              <Link to={cardIconLink()}>
                <Button color="inherit">
                  <PaymentIcon fontSize="large" />
                </Button>
              </Link>
              <Button
                variant="contained"
                onClick={signOut}
                className={classes.registerButton}
              >
                <Typography variant="body1" className={classes.registerText}>
                  ログアウト
                </Typography>
              </Button>
            </React.Fragment>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
