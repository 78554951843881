import { Auth } from 'aws-amplify';
import paths from '../../../../config/paths';

// 新規登録処理の関数（UIと連携済み）
export const signUp = async (
  email: string,
  password: string,
  lastName: string,
  firstName: string
) => {
  const params = {
    username: email,
    password: password,
    attributes: {
      given_name: firstName,
      family_name: lastName,
    },
  };
  try {
    const response = await Auth.signUp(params);
    return response;
  } catch (error) {
    console.log('error signing up:', error);
    throw error;
  }
};

// email認証処理の関数（UIと連携済み）
export const confirmSignUp = async (email: string, code: string) => {
  const username = email;
  try {
    const response = await Auth.confirmSignUp(username, code);
    return response;
  } catch (error) {
    console.log('error confirming sign up:', error);
    throw error;
  }
};

// ログイン処理の関数（UIと連携済み）
export const signIn = async (email: string, password: string) => {
  const username = email;
  try {
    const response = await Auth.signIn(username, password);
    return response;
  } catch (error) {
    console.log('error signing in:', error);
    throw error;
  }
};

// ログアウト処理の関数（UIと連携済み）
export const signOut = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    window.location.href = `${paths.landing}`;
  } catch (error) {
    console.log('error signing out: ', error);
    throw error;
  }
};

// パスワードを忘れた場合のパスワードリセット申請処理の関数
export const forgotPassword = async (email: string) => {
  const username = email;
  try {
    const response = await Auth.forgotPassword(username);
    return response;
  } catch (error) {
    console.log('error applying reset password:', error);
    throw error;
  }
};

// パスワードを忘れた場合のパスワードリセット処理の関数
export const forgotPasswordSubmit = async (
  email: string,
  code: string,
  newPassword: string
) => {
  const username = email;
  const new_password = newPassword;
  try {
    const response = await Auth.forgotPasswordSubmit(
      username,
      code,
      new_password
    );
    return response;
  } catch (error) {
    console.log('error applying reset password:', error);
    throw error;
  }
};

// ログイン認証済みユーザのパスワードリセット処理の関数
export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const old_password = oldPassword;
  const new_password = newPassword;
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.forgotPasswordSubmit(
      user,
      old_password,
      new_password
    );
    return response;
  } catch (error) {
    console.log('error changing password:', error);
    throw error;
  }
};
