import { createStyles, makeStyles, Theme } from '@material-ui/core';

const PaymentTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    priceTable: { marginTop: theme.spacing(5) },
    borderBottom: {
      borderCollapse: 'collapse',
      borderBottom: '1px solid #e6e6e6',
    },
    borderLeft: { borderCollapse: 'collapse', borderLeft: '1px solid #e6e6e6' },
    smallCol: {
      width: 150,
      // [theme.breakpoints.down('sm')]: {},
    },
    largeCol: {
      width: 300,
      [theme.breakpoints.down('sm')]: {
        width: 200,
      },
      [theme.breakpoints.down('xs')]: {
        width: 150,
      },
    },
    standardTh: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px 0 0 0',
      opacity: 0.8,
      paddingTop: 30,
      paddingBottom: 30,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 20,
        paddingBottom: 20,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    minSupportTh: {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
      paddingTop: 30,
      paddingBottom: 30,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 20,
        paddingBottom: 20,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    fullSupportTh: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0 8px 0 0',
      paddingTop: 30,
      paddingBottom: 30,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 20,
        paddingBottom: 20,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    thTextMain: {
      fontSize: theme.typography.h4.fontSize,
      color: theme.palette.common.white,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    thTextSub: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.common.white,
      marginTop: theme.spacing(1),
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 7,
      },
    },
    tableCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    price: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: 700,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 700,
      },
    },
    priceSpan: {
      fontSize: theme.typography.h4.fontSize,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
        marginLeft: theme.spacing(0),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    priceMerit: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: 500,
      marginTop: theme.spacing(1),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    priceHighlight: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 700,
      color: theme.palette.error.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    submitButton: {
      width: 200,
      color: theme.palette.common.white,
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: 90,
        height: 30,
        marginTop: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
      },
    },
    standardButton: { opacity: 0.8 },
    minSupportButton: { opacity: 0.9 },
    fullSupportButton: {},
    featureTitle: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 700,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 6,
        fontWeight: 700,
      },
    },
    featureTitleSub: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.subtitle1.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
    featureTrueOrFalse: {
      fontSize: 40,
      fontWeight: 500,
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    featureText: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    featureTextSub: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 6,
      },
    },
    featureTextHighlight: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 500,
      color: theme.palette.error.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontWeight: 700,
        fontSize: theme.typography.body2.fontSize,
      },
    },
  })
);

export default PaymentTableStyle;
