import { createStyles, makeStyles, Theme } from '@material-ui/core';

const CurriculumStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    alert: {
      width: 600,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        width: 350,
      },
    },
    group: {
      width: 1100,
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        width: 710,
      },
      [theme.breakpoints.down('xs')]: {
        width: 350,
      },
    },
  })
);

export default CurriculumStyle;
