import { createStyles, makeStyles, Theme } from '@material-ui/core';

const TermsStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {},
    whiteContent: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
        textAlign: 'center',
      },
    },
    whiteContentTitle: {
      fontSize: theme.typography.h2.fontSize,
      color: theme.palette.common.black,
      fontWeight: 'bold',
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h2.fontSize,
        marginBottom: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h5.fontSize,
        marginBottom: theme.spacing(3),
      },
    },
    whiteContentText: {
      fontSize: theme.typography.h3.fontSize,
      color: theme.palette.common.black,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    whiteContentSubText: {
      width: 270,
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.common.black,
      textAlign: 'justify',
    },
    term: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    termWrapper: {
      width: 800,
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        width: 500,
        marginBottom: theme.spacing(3),
        textAlign: 'left',
      },
      [theme.breakpoints.down('sm')]: {
        width: 400,
        marginBottom: theme.spacing(3),
        textAlign: 'left',
      },
      [theme.breakpoints.down('xs')]: {
        width: 300,
        marginBottom: theme.spacing(3),
        textAlign: 'left',
      },
    },
    termTextWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    termIcon: {
      marginRight: theme.spacing(1),
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    termText: {
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    lastLine: { textAlign: 'right' },
  })
);

export default TermsStyle;
