import React from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import paths from '../../../../config/paths';
import thankImg from './thankImg.png';
import ThankStyle from './Thank.style';

const Thank: React.VFC = () => {
  const classes = ThankStyle();
  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography align="center" className={classes.titleText}>
            認証に成功しました
            <br />
            ログインしてサービスをお楽しみください
          </Typography>
          <Grid container spacing={2} className={classes.wrapper}>
            <Grid item xs={12}>
              <img src={thankImg} alt="Thank you" className={classes.image} />
            </Grid>
            <Link to={paths.userAuth.signIn} className={classes.link}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                ログイン画面へ
              </Button>
            </Link>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to={paths.userAuth.signUp}>
                  アカウントを持っていない方
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Thank;
