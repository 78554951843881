import { createStyles, makeStyles, Theme } from '@material-ui/core';

const CardStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 345,
    },
    link: { textDecoration: 'none' },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    cardHeader: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 600,
    },
    title: { fontSize: theme.typography.h4.fontSize, fontWeight: 600 },
    subText: { margin: '4px 0' },
  })
);

export default CardStyle;
