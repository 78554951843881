import { createStyles, makeStyles, Theme } from '@material-ui/core';

const FooterStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '50px 0',
    },
    legalWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 550,
      fontSize: theme.typography.body2.fontSize,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    link: {
      color: theme.palette.common.white,
      textDecoration: 'none',
      marginLeft: theme.spacing(10),
      [theme.breakpoints.down('xs')]: {
        // display: 'block',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(0),
      },
    },
    pTag: {
      width: '50%',
      textAlign: 'center',
    },
    mobileLegalWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default FooterStyle;
