import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  Typography,
  Container,
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import PaymentTable from '../../../components/paymentTable/PaymentTable';
import { PaymentStyle } from './Payment.style';

const Payment: React.VFC = () => {
  const classes = PaymentStyle();
  const [payMode, setPayMode] = useState('installments');

  return (
    <div className={classes.root}>
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PaymentIcon />
          </Avatar>
          <Typography align="center" className={classes.titleText}>
            入塾プランを選択してください
          </Typography>
          <div className={classes.toggleButtons}>
            <Button
              type="button"
              onClick={() => {
                setPayMode('installments');
              }}
              fullWidth
              variant="contained"
              color="primary"
              className={
                payMode === 'installments'
                  ? classes.toggleButton
                  : classes.toggleButtonGray
              }
            >
              月々支払い
            </Button>
            <Button
              type="button"
              onClick={() => {
                setPayMode('lump');
              }}
              fullWidth
              variant="contained"
              color="primary"
              className={
                payMode === 'lump'
                  ? classes.toggleButton
                  : classes.toggleButtonGray
              }
            >
              一括支払い
            </Button>
          </div>
          <div className={classes.enrollmentFee}>
            <Typography className={classes.enrollmentFeeText}>
              各プランに入塾金：
              <span className={classes.enrollmentFeeHilight}>33,000</span>
              円（税込み）が加算されます
            </Typography>
          </div>
          <PaymentTable payMode={payMode} buttonDisabled={false} />
        </div>
      </Container>
    </div>
  );
};

export default Payment;
