import React from 'react';
import { Typography } from '@material-ui/core';
import PrivacyStyle from './Privacy.style';

const Privacy: React.VFC = () => {
  const classes = PrivacyStyle();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.whiteContent}>
          <div className={classes.pv}>
            <Typography className={classes.whiteContentTitle}>
              プライバシーポリシー
            </Typography>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  阿部直也（以下「当方」といいます。）は、あべちゃんのフロントエンド塾（以下「当サイト」といいます。）において当方の提供するサービス（以下「本サービス」といいます。）におけるユーザーについての個人情報（お名前、ご住所、電話番号等の個人を識別することができる情報のことを指し、他の情報と組み合わせて照合することにより個人を識別することができる情報を含む。）を含む利用者情報の取り扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。なお、本ポリシー上の用語は、特に説明のない限り本サービスの利用規約上の用語と同義とします。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>1.</Typography>
                <Typography className={classes.pvText}>
                  個人情報の取得について
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  当サイトは、適正な手続きにより個人情報を取得します。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>2.</Typography>
                <Typography className={classes.pvText}>
                  収集する利用者情報及びその収集方法
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  本ポリシーにおける「利用者情報」とは、ユーザーの識別にかかる情報、通信サービス上の行動履歴、その他ユーザーの端末においてユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当方が収集するものを意味することとします。本サービスにおいて当方が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑴</Typography>
                <Typography className={classes.pvText}>
                  ユーザーからご提供いただく、氏名、メールアドレス、その他当方が定める入力フォームにユーザーが入力する情報(クレジットカード情報は、決済代行サービス会社が保持しており、当社はクレジットカード情報を一切保持しません。)
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑵</Typography>
                <Typography className={classes.pvText}>
                  ユーザーが本サービスを利用するにあたって、他サービスと連携を許可することにより、当該他サービスからご提供いただく情報（当該外部サービスでユーザーが利用するID、その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報）
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑶</Typography>
                <Typography className={classes.pvText}>
                  ユーザーが本サービスを利用するにあたって、当方が本サービスへのアクセス状況やその他ご利用方法から収集する情報（端末情報、ログ情報、機器情報、位置情報、Cookie等が含まれます。）
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑷</Typography>
                <Typography className={classes.pvText}>
                  本サービスがトラフィックデータ収集のために利用しているGoogleアナリティクスから収集するユーザー属性情報（年齢、性別、興味や関心など）。なお、これらのデータは個人を特定できない形式に加工された上で収集されます。Googleアナリティクスの詳細についてはGoogleアナリティクスサービス利用規約をご確認ください。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>3.</Typography>
                <Typography className={classes.pvText}>利用目的</Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  ユーザーから取得した利用者情報は、以下の目的のために利用されます。
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑴</Typography>
                <Typography className={classes.pvText}>
                  本サービスに関する登録の受付および本人確認等、本サービスの提供、維持、保護および改善のため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑵</Typography>
                <Typography className={classes.pvText}>
                  本サービスに関するご案内、お問い合わせ等への対応のため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑶</Typography>
                <Typography className={classes.pvText}>
                  本サービスに関する当方の規約、ポリシー等（以下「規約等」といいます。）に違反する行為への対応のため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑷</Typography>
                <Typography className={classes.pvText}>
                  本サービスに関する規約等の変更、システムメンテナンスその他重要なお知らせ等について通知するため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑸</Typography>
                <Typography className={classes.pvText}>
                  課金額の計算、確認および請求
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑹</Typography>
                <Typography className={classes.pvText}>
                  当方の他のサービス、メールマガジンの配信のため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑺</Typography>
                <Typography className={classes.pvText}>
                  当方のサービスに関連して、個人を特定できない形式に加工した統計データを作成するため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑻</Typography>
                <Typography className={classes.pvText}>
                  当方または第三者の広告配信または表示のため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑼</Typography>
                <Typography className={classes.pvText}>
                  当方または第三者が提供しているサービスまたは将来提供するサービスに関するマーケティング調査、統計および分析等に利用するため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑽</Typography>
                <Typography className={classes.pvText}>
                  上記の利用目的に付随する利用目的のため
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑾</Typography>
                <Typography className={classes.pvText}>
                  その他当方のサービス、コンテンツおよび広告の開発、提供、メンテナンスおよび向上のため
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>4.</Typography>
                <Typography className={classes.pvText}>
                  個人情報の第三者提供
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography>
                  当方は、利用者情報のうち、個人情報については、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで第三者（個人情報の保護に関する法律第23条に定める「第三者」をいいます。）に提供しません。ただし、次に掲げる場合はこの限りではありません。
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑴</Typography>
                <Typography className={classes.pvText}>
                  当方が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑵</Typography>
                <Typography className={classes.pvText}>
                  合併その他の事由による事業の承継に伴って個人情報が提供される場合
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑶</Typography>
                <Typography className={classes.pvText}>
                  国の機関もしくは地方公共団体、またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑷</Typography>
                <Typography className={classes.pvText}>
                  その他、個人情報保護法その他の法令で認められる場合
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>5.</Typography>
                <Typography className={classes.pvText}>
                  個人情報の開示
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  当方は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザー（当該開示請求対象の個人情報によって特定されうる者に限ります。）ご本人からの請求であることを確認の上で、当該ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当サイトが開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、別途当サイトが指定する手数料をご請求させていただく可能性がありますので、あらかじめ御了承ください。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>6.</Typography>
                <Typography className={classes.pvText}>
                  個人情報の訂正、利用停止および消去
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>1.</Typography>
                <Typography className={classes.pvText}>
                  当方は、ユーザーから個人情報の訂正または利用停止を求められた場合には、ユーザーご本人（訂正または利用停止を求められている個人情報によって特定されうる者に限ります。）からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨を当該ユーザーに通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知します。
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>2.</Typography>
                <Typography className={classes.pvText}>
                  当方は、ユーザーから、当該ユーザーの個人情報について消去を求められた場合、当方が当該請求に応じる必要があると判断した場合は、ユーザーご本人（消去を求められている個人情報によって特定されうる者に限ります。）からのご請求であることを確認の上で、個人情報の消去を行い、その旨を当該ユーザーに通知します。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>7.</Typography>
                <Typography className={classes.pvText}>
                  Cookie、ログ情報の保存、利用
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  当方では、本サービスの利便性をあげるため、Cookie、アクセス解析、統計データなどを保存、利用します。また、当方はCookieやJavaScriptなどの技術を利用し、ユーザーの行動履歴を取得することがあります。なお、これらには個人情報は含まれません。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>8.</Typography>
                <Typography className={classes.pvText}>免責</Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  次に掲げる場合には、第三者による個人情報の取得に関し、当方は何らの責任を負いません。
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑴</Typography>
                <Typography className={classes.pvText}>
                  ユーザー自らが当サービス上の機能または別の手段を用いて第三者に個人情報を明らかにする場合
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑵</Typography>
                <Typography className={classes.pvText}>
                  ユーザーが本サービス上に入力した個人情報以外の情報、活動情報等により、期せずして個人が特定できてしまった場合
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑶</Typography>
                <Typography className={classes.pvText}>
                  第三者が当サイト外で個人情報その他の利用者情報を取得した場合
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>⑷</Typography>
                <Typography className={classes.pvText}>
                  当方の責めに帰すべき事由によらず、ユーザーご本人以外がユーザー個人を識別できる情報(ID・パスワード等を含みます。)を入手した場合
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>9.</Typography>
                <Typography className={classes.pvText}>
                  プライバシーポリシーの変更手続き
                </Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  当方は利用者情報の取り扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本ポリシーを変更することができるものとします。本ポリシーを変更する際は本サイト上にて公表させていただきます。改定されたプライバシーポリシーが本サイトに掲載された時点で、登録ユーザーは変更に同意したものとし、同時点から効力を生じるものとします。ただし、法令上、ユーザーの同意が必要となるような変更を行う場合、変更後のプライバシーポリシーは、当サイト所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvIcon}>10.</Typography>
                <Typography className={classes.pvText}>お問い合わせ</Typography>
              </div>
              <div className={classes.pvTextWrapper}>
                <Typography className={classes.pvText}>
                  本ポリシーならびに利用者情報に関するご意見、ご質問、苦情のお申出、その他のお問い合わせは「お問い合わせフォーム」よりお願いいたします。
                </Typography>
              </div>
            </div>
            <div className={classes.pvWrapper}>
              <div className={classes.lastLine}>
                <Typography className={classes.pvText}>
                  2021 年 3 月 13 日制定
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
