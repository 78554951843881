import React from 'react';
import { Typography } from '@material-ui/core';
import TermsStyle from './Terms.style';

const Terms: React.VFC = () => {
  const classes = TermsStyle();
  return (
    <div className={classes.content}>
      <div className={classes.whiteContent}>
        <div className={classes.term}>
          <Typography className={classes.whiteContentTitle}>
            あべちゃんのフロントエンド塾利用規約
          </Typography>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第1条</Typography>
              <Typography className={classes.termText}>
                利用規約について
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                あべちゃんのフロントエンド塾（以下「本サービス」といいます。）は、阿部直也（以下「当方」といいます。）が開発運営するプログラミング学習サービスです。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                本規約は、本サービスが提供される条件、及び当方と登録ユーザーの皆様との間に発生する権利義務関係について定めることを目的としています。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                本サービスを利用される際には、本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                本規約は、登録ユーザーが本サービスを利用する上で生じる当方とのすべての関係に適用されます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>5.</Typography>
              <Typography className={classes.termText}>
                本規約の内容と、本規約外で本サービスについて説明している内容等が異なる場合は、本規約の規定が優先して適用されます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>6.</Typography>
              <Typography className={classes.termText}>
                ユーザーが本規約に同意することによって、ユーザーと当方との間で本サービスの利用契約が成立します。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第2条</Typography>
              <Typography className={classes.termText}>
                ユーザーの定義と登録
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                本サービスの登録ユーザーとは、ユーザー登録のために必要な内容をデータ入力・送信し、当方が承認して第４項に定める利用登録を完了した個人を指します。TwitterやGitHubなど、外部サービスの認証システムを経由して当方が承認した個人も含みます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                本サービスの利用を希望する方（以下「登録希望者」といいます。）は、本規約を遵守する事に同意し、かつ当方の定める一定の情報（以下「登録事項」といいます。）を当方の定める方法で当方に提供することにより、当方に対し、本サービスの利用の登録を申請することができます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                前項の申請につき、登録希望者が未成年者の場合は、登録に伴い親権者の同意を必要とし、本規約への同意をもって、親権者の同意があったものとみなします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                当方は、当方の基準に従って、第1項に定める登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、登録を認めた場合は、その旨を登録申請者に通知します。本サービスの利用登録は、当方がこの通知を行ったことをもって完了したものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>5.</Typography>
              <Typography className={classes.termText}>
                当方は、登録ユーザーが、次の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消、もしくはサービス利用規約に基づく契約を解除することができます。また、それによりユーザーまたは第三者が被った損害に関し、当方に故意又は重過失があったときを除き、当方は責任を負いません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑴</Typography>
              <Typography className={classes.termText}>
                当方に提供した登録事項の全部または一部につき虚偽、誤記または記入漏れがあった場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑵</Typography>
              <Typography className={classes.termText}>
                ユーザー登録後に登録事項の内容に変更が生じた際に登録ユーザー自身が速やかに登録変更を行わなかった場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑶</Typography>
              <Typography className={classes.termText}>
                ユーザー登録をしたにもかかわらず、登録ユーザーが６か月間以上本サービスを利用しなかった場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑷</Typography>
              <Typography className={classes.termText}>
                登録ユーザーが本規約に違反した場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑸</Typography>
              <Typography className={classes.termText}>
                未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかったことが判明した場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑹</Typography>
              <Typography className={classes.termText}>
                登録ユーザーが反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者またはこれに属する者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当方が判断した場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑺</Typography>
              <Typography className={classes.termText}>
                登録ユーザーが過去に当方との契約に違反した者またはその関係者であると当方が判断した場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑻</Typography>
              <Typography className={classes.termText}>
                第7条に定める措置を受けたことがある場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑼</Typography>
              <Typography className={classes.termText}>
                その他当方が登録を適当でないと判断した場合
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第3条</Typography>
              <Typography className={classes.termText}>
                ユーザーの責任
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーが本サービスを利用する場合、インターネットにアクセスする必要がありますが、そのために必要な機器、ソフトウェア、通信手段等は登録ユーザーご自身が各自の責任と費用において適切に準備、設置、操作してください。当方は登録ユーザーのアクセス環境について一切関与せず、これらの準備、設置、操作に関する責任を負いません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                当方より登録ユーザーに対して連絡を行う際には、登録メールアドレス宛ての電子メール、もしくは本サービス上での「お知らせ」にて連絡を行います。登録ユーザーは当方からの電子メールを受信できるよう、登録メールアドレス情報を適切に登録し、何らかの理由で受信ができなくなった場合には、速やかに登録メールアドレスの変更を適切に行ってください。当方からの電子メールが受信できなかったために登録ユーザーが被った不利益については、登録ユーザー自身に責任があるものとし、当方に故意または重過失があった場合を除いて、当方は一切の責任を負わないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                当方は登録ユーザーに対して、ご利用後の感想を伺う場合や、会員向けコンテンツなどのお得な情報を、メールにて配信することがありますが、登録ユーザーは当方からのお得情報のメールに関しては受け取りを拒否することが可能です。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーは、本サービス利用中に、何らかの事情でデータが破損・滅失した場合のために、登録ユーザー側で定期的にデータをバックアップすることを遵守するものとします。登録ユーザーが必要なバックアップを取らなかったことによる損害については、当方は責任を負わないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>5.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーは、登録事項に変更があった場合、当方の定める方法により当該変更事項を遅滞なく当方に通知するものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第4条</Typography>
              <Typography className={classes.termText}>
                ユーザーIDまたは登録メールアドレス、パスワードの管理
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーは、自己の責任において、本サービスに関するユーザーID、登録メールアドレス、およびパスワードを適切に管理、保管してください。これらを第三者に利用させたり、貸与、譲渡、名義変更、または売買等をしたりしてはならないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                ユーザーID、登録メールアドレスおよびパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとし、当方は一切の責任を負いません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                ユーザーIDを第三者へ転売、譲渡、貸与することはできません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                ユーザーIDは法人や個人事業主の方で研修など事業用途としてはご利用いただけません。法人または個人事業主にて事業用途としてアカウントを利用されたい場合は、お問い合わせよりご連絡ください。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第5条</Typography>
              <Typography className={classes.termText}>権利帰属</Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                当方は本サービスに含まれるコンテンツ（動画・テキスト・画像・ソースコード・ソフトウェア・レッスンの構成等）に関する一切の著作権（著作権法27条及び28条に規定する権利を含む。）、商標権、特許権、その他の知的財産権（それらの権利を取得し、またはそれらの登録等を出願する権利を含む）を保有しており、当方の権利は日本の著作権法および著作権に関する国際法によって保護されています。ただし、本サービスに含まれるソースコードの中にはオープンソースライセンスに基づいて提供されているものがあり、当該オープンソースライセンスに本規約より優先されることが明示的に規定された条項が含まれる場合、当該規定された事項についてはその優先される条項が適用されます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーは、投稿データについて、当方に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の登録ユーザーに対しても、無償で、自己の投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーが本サービスに含まれるコンテンツを利用して利益を得た場合、当方は当該ユーザーに対してその利益相当額の金額を請求できる権利を有するものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                本サービスに使用されている全てのソフトウェアは、知的財産権に関する法令等により保護されている財産権及び営業秘密を含んでいます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>5.</Typography>
              <Typography className={classes.termText}>
                当方による事前の書面による承諾を得ることなく、いかなる手段・目的によるかを問わず、本サービスのコンテンツを収集・ダウンロード・複製することを禁じます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>6.</Typography>
              <Typography className={classes.termText}>
                当方による事前の書面による承諾を得ることなく、本サービスのコンテンツを使用した学習教材の作成（動画の埋め込み、レッスンへのリンク、転載、引用の範囲を超えた利用等を含む）、提供、および販売を行うことを禁じます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>7.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーは、当方および当方から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第6条</Typography>
              <Typography className={classes.termText}>広告掲載</Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termText}>
                当方は、当方の判断により、本サービスに当方又は第三者の広告を掲載することができるものとし、ユーザーはこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当方によって随時変更されます。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第7条</Typography>
              <Typography className={classes.termText}>禁止事項</Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                当方は、登録ユーザーの行為（不作為を含む。）が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除、本サービスの利用の一時的に停止、登録ユーザーとしての登録の抹消、サービス利用契約の解除、その他当方が必要と判断した措置またはその全てをすることができます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑴</Typography>
              <Typography className={classes.termText}>
                本規約のいずれかの条項に違反する行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑵</Typography>
              <Typography className={classes.termText}>
                登録事項に虚偽の情報を申告して登録を行う行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑶</Typography>
              <Typography className={classes.termText}>
                6ヶ月以上本サービスの利用を行わない
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑷</Typography>
              <Typography className={classes.termText}>
                当方からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑸</Typography>
              <Typography className={classes.termText}>
                登録ユーザー本人もしくは第三者のメールアドレス、会員ID、またはパスワードの不正使用
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑹</Typography>
              <Typography className={classes.termText}>
                本サービスの受講中に、金銭を受け取りプログラミング講師を行う行為、または金銭を支払い本サービスを過去に受講していたユーザーより指導を受ける行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑺</Typography>
              <Typography className={classes.termText}>
                法令に違反する行為、および違法な行為を勧誘または助長する行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑻</Typography>
              <Typography className={classes.termText}>
                当方、本サービスの他の登録ユーザーまたはその他の第三者に対する詐欺または脅迫行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑼</Typography>
              <Typography className={classes.termText}>
                当方、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑽</Typography>
              <Typography className={classes.termText}>
                本サービスのネットワークまたはシステム等に過度な負荷をかける行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑾</Typography>
              <Typography className={classes.termText}>
                本サービスの運営を妨害するおそれのある行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑿</Typography>
              <Typography className={classes.termText}>
                当方のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒀</Typography>
              <Typography className={classes.termText}>
                第三者に成りすます行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒁</Typography>
              <Typography className={classes.termText}>
                本サービスの他の登録ユーザーのアカウントを利用する行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒂</Typography>
              <Typography className={classes.termText}>
                当方が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒃</Typography>
              <Typography className={classes.termText}>
                本サービスの他の利用者の情報の収集
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒄</Typography>
              <Typography className={classes.termText}>
                当方、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒅</Typography>
              <Typography className={classes.termText}>
                本サービス内のコンテンツについて、無断で複製、アップロード、ダウンロード、掲示、伝送、または配布等をする行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒆</Typography>
              <Typography className={classes.termText}>
                反社会的勢力等への利益供与
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⒇</Typography>
              <Typography className={classes.termText}>
                その他、公序良俗、一般常識に反する行為、または当方が不適切と判断する行為
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                前項各号のいずれかの事由に該当した場合、登録ユーザーは、当方に対して負っている債務の一切について当然に期限の利益を失い、直ちに当方に対して全ての債務の支払を行わなければなりません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                当方は、本条に基づき当方が行った行為により登録ユーザーに生じた損害について一切の責任を負いません。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第8条</Typography>
              <Typography className={classes.termText}>退会</Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                登録ユーザーは、当方所定の方法で本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                退会時に当方に対して負っている債務がある場合は、登録ユーザーは、当方に対して負っている債務の一切について当然に期限の利益を失い、直ちに当方に対して全ての債務の支払いを行わなければなりません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                退会後の利用者情報の取り扱いについては、第12条の規定に従うものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第9条</Typography>
              <Typography className={classes.termText}>
                本サービスの内容の変更、終了
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                当方は、当方の都合により、本サービスの内容を変更し、または提供を終了することができます。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography>
                当方は、前項に基づき当方が行った措置に基づき登録ユーザーに生じた損害について、当方に故意または重過失があった場合を除いて一切の責任を負いません。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第10条</Typography>
              <Typography className={classes.termText}>
                保証の否認及び免責
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                当方は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則などに適合すること、及び不具合が生じないことについて、何らの保証もするものでもありません。当方は、サービス向上のため、随時改良、改善を行いますが、登録ユーザーから特定の機能につき改善、修補の要求がなされた場合でも、当該要求に基づく改善、修補を行う義務は負わないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                当方は、当方による本サービスの中断、停止、終了、利用不能または変更、登録ユーザーが本サービスに送信したメッセージまたは情報の削除または消失、登録ユーザーの登録の抹消、本サービスの利用による登録データの消失または機器の故障もしくは損傷、その他本サービスに関して登録ユーザーが被った損害（以下「ユーザー損害」といいます。）につき、当方の故意または重過失による場合を除いては、賠償する責任を一切負わないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                本サービスの内容により登録ユーザーに損害が発生したとしても、当方に故意又は重過失があった場合を除き、当方は責任を負いません。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                何らかの理由により当方が責任を負う場合であっても、当方は、ユーザー損害につき、過去６ヶ月間に登録ユーザーが当方に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>5.</Typography>
              <Typography className={classes.termText}>
                当方は、登録ユーザー同士の通信や活動に関与しないものとし、本サービスまたは当方ウェブサイトに関連して登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、連絡、紛争などについては、当方は一切責任を負いません。万一ユーザー同士の紛争があったときは、当事者ユーザーの悪質行為と判断される場合、または当方に故意又は重過失があった場合を除き、当事者同士で解決するものとし、当方はその解決および紛争により生じた損害等に対する責任を負わないものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>6.</Typography>
              <Typography className={classes.termText}>
                当方は日本国外での本サービスの利用に関連して利用者に発生した損害について、当方に故意又は重過失があったときを除き、責任を負わないものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第11条</Typography>
              <Typography className={classes.termText}>秘密保持</Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termText}>
                登録ユーザーは、本サービスに関連して当方が登録ユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、当方の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第12条</Typography>
              <Typography className={classes.termText}>
                ユーザー情報の取り扱い
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                当方によるユーザー情報の取り扱いについては、当サイトのプライバシーポリシーの定めるものとし、登録ユーザーは、ユーザー登録により、このプライバシーポリシーに従って当方が登録ユーザーの利用者情報を取り扱うことについて同意するものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                当方は、登録ユーザーが当方に提供した情報、データ等を、個人を特定できない形の統計的な情報として、当方の判断で、利用および公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第13条</Typography>
              <Typography className={classes.termText}>
                本サービスの停止等
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                当方は、次の各号のいずれかに該当する場合には、予告なしに本サービスの全部または一部の提供を停止または中断することがあります。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑴</Typography>
              <Typography className={classes.termText}>
                本サービスに係わるシステムの点検または保守作業を緊急に行う場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑵</Typography>
              <Typography className={classes.termText}>
                本サービスの提供に必要なインフラストラクチャー、外部サービスに不具合が発生した場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑶</Typography>
              <Typography className={classes.termText}>
                地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>⑷</Typography>
              <Typography className={classes.termText}>
                その他、当方が停止または中断を必要と判断した場
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                当方は、本サービスの内容を、予告なしに変更、追加、削除することがあります。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>3.</Typography>
              <Typography className={classes.termText}>
                当方は、本サービスを予告なしに終了することがあります。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>4.</Typography>
              <Typography className={classes.termText}>
                当方は、本条に基づき当方が行った措置によりユーザーに生じた損害について、当方に故意又は重過失があったときを除き、当方は責任を負いません。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第14条</Typography>
              <Typography className={classes.termText}>分離可能性</Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termText}>
                本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの条項および一部が無効または執行不能と判断された条項の残りの部分は、継続して完全に効力を有するものとします。
              </Typography>
            </div>
          </div>
          <div className={classes.termWrapper}>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>第15条</Typography>
              <Typography className={classes.termText}>
                準拠法および管轄裁判所
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>1.</Typography>
              <Typography className={classes.termText}>
                本規約の有効性、解釈および履行については、準拠法は日本法とし、日本法に従って解釈されるものとします。
              </Typography>
            </div>
            <div className={classes.termTextWrapper}>
              <Typography className={classes.termIcon}>2.</Typography>
              <Typography className={classes.termText}>
                ユーザーと当方の間で生じた、本規約または本サービスに起因しまたは関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.whiteContent}>
          <div className={classes.term}>
            <Typography className={classes.whiteContentTitle}>
              あべちゃんのフロントエンド塾有料会員利用規約
            </Typography>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第1条</Typography>
                <Typography className={classes.termText}>
                  有料会員利用規約について
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>1.</Typography>
                <Typography className={classes.termText}>
                  あべちゃんのフロントエンド塾有料会員利用規約（以下「本有料会員規約」といいます。）は、阿部直也（以下「当方」といいます。）が運営するウェブサービス「あべちゃんのフロントエンド塾」の有料サービス（以下「有料サービス」といいます。）の提供条件および当方と有料サービスの登録ユーザー（以下、「有料会員」といいます）との間の権利義務関係を定めるものです。有料サービスの利用に際しては、本有料会員規約の全文をお読みいただいた上で、本有料会員規約に同意いただく必要があります。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>2.</Typography>
                <Typography className={classes.termText}>
                  有料会員は、有料会員への登録および有料サービスの利用に際して、別途定めるあべちゃんのフロントエンド塾利用規約利用規約に同意した上で、本有料会員規約に同意するものとします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>3.</Typography>
                <Typography className={classes.termText}>
                  本有料会員規約の内容と、本有料会員規約外における有料サービスの説明等とが異なる場合は、本有料会員規約の規定が優先して適用されるものとします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>4.</Typography>
                <Typography className={classes.termText}>
                  当方は、当方が必要と判断する場合に、本有料会員規約を予告なしに変更することができるものとします。変更後の本有料会員規約は、本サイト上で公表するものとし、有料会員は、変更後の本有料会員規約の公表後、有料サービスを利用した場合、本有料会員規約の変更に同意したものとみなします。当該変更に同意いただけない場合は、有料サービスを利用できませんので、直ちに第５条に従い、解約手続きを行うものとします。ただし、有料会員の利用に重大な影響をあたえる変更の場合には、当方の判断により合理的な事前告知期間を設け、当方が定めた方法によって有料会員に通知するものとします。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第2条</Typography>
                <Typography className={classes.termText}>
                  有料会員登録
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>1.</Typography>
                <Typography className={classes.termText}>
                  有料サービスの利用を希望する方（以下「有料サービス登録希望者」といいます。）は、本有料会員規約を遵守する事に同意し、かつ、有料サービスの利用に必要となる当方の定める一定の情報（以下「有料サービス登録事項」といいます。）を、当方の定める方法で当方に提供することにより、当方に対し、有料サービスの利用の登録を申請することができます。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>2.</Typography>
                <Typography className={classes.termText}>
                  前項につき、有料サービス登録希望者が未成年者の場合は、登録に伴い親権者の同意を必要とし、本有料会員規約の同意をもって、親権者の同意があったものとみなします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>3.</Typography>
                <Typography className={classes.termText}>
                  当方は、当方の基準に従って、第1項に定める登録申請を行った有料サービス登録希望者（以下「有料サービス登録申請者」といいます。）の登録の可否を判断し、登録を認めた場合は、その旨を登録申請者に通知します。本サービスの利用の登録は、当方がこの通知を行ったことをもって完了したものとします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>4.</Typography>
                <Typography className={classes.termText}>
                  前項の有料サービスの登録完了時に、有料サービス利用契約が有料会員と当方の間に成立し、有料会員は有料サービスを利用することができるようになります。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>5.</Typography>
                <Typography className={classes.termText}>
                  当方は、有料サービス登録申請者が、次の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>⑴</Typography>
                <Typography className={classes.termText}>
                  当方に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>⑵</Typography>
                <Typography className={classes.termText}>
                  未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかったことが判明した場合
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>⑶</Typography>
                <Typography className={classes.termText}>
                  反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者またはこれに属する者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当方が判断した場合
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>⑷</Typography>
                <Typography className={classes.termText}>
                  登録希望者が過去当方との契約に違反した者またはその関係者であると当方が判断した場合
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>⑸</Typography>
                <Typography className={classes.termText}>
                  その他、当方が登録を適当でないと判断した場合
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第3条</Typography>
                <Typography className={classes.termText}>
                  利用料金及び支払方法
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>1.</Typography>
                <Typography className={classes.termText}>
                  有料会員は、有料サービスを利用するにあたり、当方に対し、当方が規定する月額利用料金（以下「利用料金」といいます）をお支払いいただく必要があります。利用料金は、当方が各サービスごとに別途定め、購入ページに表示します。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>2.</Typography>
                <Typography className={classes.termText}>
                  有料サービスの月額利用における「1ヶ月」とは、有料会員がクレジットカード決済を実行する操作をし、有効な決済が行われた時（クレジットカードで支払うボタンを押し、決済が行われた時）から１か月（お支払日の翌月同日同時間まで。翌月同日が存在しない場合は、翌月末日の同時間まで。１か月以内に解約手続きがあった場合は解約手続きを行った日まで）を指すものとします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>3.</Typography>
                <Typography className={classes.termText}>
                  有料サービスの最初の１か月分の利用料金は、有料サービスの登録時に支払うものとします。翌月以降の利用料金は、翌月以降の、有料サービス登録日時と同日同時間（翌月以降に登録日の同日が存在しない場合は、当該月の末日となります。以下「更新日時」といいます。）に、翌1か月分の利用料金をお支払いいただくものとします。なお、有料会員は、有料サービスの解約を希望する場合、更新日時までに、第５条に定める解約手続きを行う必要があります。更新日時までに解約手続きが行われなかった場合、有料サービスは自動的に１か月間更新され、有料会員は、更新日時に翌1か月分の利用料金を支払う義務が生じますのでご注意ください。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>4.</Typography>
                <Typography className={classes.termText}>
                  当方は、サービス内容の変更等により、利用料金を改定する場合があります。なお、利用料金を改定する場合は、当方は所定の方法により有料会員に事前に通知するものとします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>5.</Typography>
                <Typography className={classes.termText}>
                  利用料金は、有料サービスの登録時、更新日時にクレジットカード決済により支払うものとします。なお、更新日時までに解約手続きが行われなかった場合は、第３項の定めに基づいて、更新日時に自動的にクレジットカード決済が行われます。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>6.</Typography>
                <Typography className={classes.termText}>
                  有料会員が利用料金の支払を遅延した場合には、有料会員は年14.6%の割合による遅延損害金を当方に支払うものとします。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>7.</Typography>
                <Typography className={classes.termText}>
                  有料会員が有料サービスを解約した場合、お支払いいただいた利用料金は、理由のいかんにかかわらず、返金を行いません。また、利用料金のお支払いから1か月以内に解約した場合においても、残存期間の利用料金の返金は行いませんので、ご注意ください。お支払いから1か月以内に解約した場合、即時有料サービスがご利用不可になりますので、ご注意ください。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第4条</Typography>
                <Typography className={classes.termText}>
                  有料会員登録の解除
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termText}>
                  有料会員があべちゃんのフロントエンド塾利用規約および本有料会員規約に違反する行為または違反するおそれのある行為を行ったとき、あべちゃんのフロントエンド塾利用規約第7条に規定するいずれかの事由に該当したとき、または利用料金の支払いを怠ったときには、当方は、事前に通知または催告することなく、当該有料会員の投稿データの削除、当該有料会員について有料サービスの利用の一時停止、有料会員としての登録の抹消、有料サービス利用契約の解除、またはその全ての措置を取ることができます。当該事由により解除となった場合、解除日を含む有料サービス提供期間の利用料金が発生するものとします。また、これらの措置によって有料会員が被った損害について、当方の故意または重過失による場合を除き、当方は一切の責任を負いません。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第5条</Typography>
                <Typography className={classes.termText}>
                  有料会員登録の解約
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>1.</Typography>
                <Typography className={classes.termText}>
                  有料会員は、当方所定の方法で当方に通知することにより、有料サービスから退会し、自己の有料会員としての登録を抹消することができます。なお、更新日時までに解約手続きを行わない場合、有料サービスは自動更新されますのでご注意ください。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>2.</Typography>
                <Typography className={classes.termText}>
                  前項に関して、ユーザーの不注意のほか、当方の定期的または緊急のメンテナンス、システム障害、通信状態の不良等により、更新日時において解約することが出来なかった場合においても、当方は責任を負いかねますので、ご了承ください。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>3.</Typography>
                <Typography className={classes.termText}>
                  退会時、当方に対して負っている債務が有る場合は、有料会員は、当方に対して負っている債務の一切について当然に期限の利益を失い、直ちに当方に対して全ての債務の支払いを行わなければなりません。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第6条</Typography>
                <Typography className={classes.termText}>返金</Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termText}>
                  本サービスの性質上、理由のいかんを問わず、有料会員がお支払いいただいた料金の返金は一切行わないものとします。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第7条</Typography>
                <Typography className={classes.termText}>
                  譲渡等の禁止
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termText}>
                  有料会員は、当方の書面による事前の承諾なく、有料サービス利用契約上の地位または本有料会員規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第8条</Typography>
                <Typography className={classes.termText}>免責事項</Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>1.</Typography>
                <Typography className={classes.termText}>
                  有料会員は本サービスの利用をご自身のリスク判断において行うものであることに同意し、本サービスによって引き起こされた直接的および間接的な損害について、当方に故意又は重過失があったときを除き、当方は責任を負いません。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>2.</Typography>
                <Typography className={classes.termText}>
                  本サービスは完全なる動作保証を行うものではなく、天変地異や予期せぬ通信障害、もしくはサービス運営に必要なメンテナンス作業などによって不具合が生じる可能性があります。有料会員はこれらの可能性があることを認識し、これらの不具合から生じる損害に関して、当方に故意又は重過失があったときを除き、当方は責任を負いません。
                </Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>3.</Typography>
                <Typography className={classes.termText}>
                  なんらかの理由により当方が責任を負う場合であっても、当方に故意又は重過失があったときを除き、当方は、当該利用者が直接かつ現実に被った損害の範囲内で、かつ、有料会員から当該損害が発生した月に受領した利用料の額を上限として損害賠償責任を負うものとします。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termIcon}>第9条</Typography>
              </div>
              <div className={classes.termTextWrapper}>
                <Typography className={classes.termText}>
                  本規約に定めのない事項については、「あべちゃんのフロントエンド塾利用規約」を適用します。
                </Typography>
              </div>
            </div>
            <div className={classes.termWrapper}>
              <div className={classes.lastLine}>
                <Typography className={classes.termText}>
                  2021 年 3 月 13 日制定
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
