import { createStyles, makeStyles, Theme } from '@material-ui/core';

const LandingStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    alert: {
      margin: 'auto',
      marginTop: 80,
      width: 600,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: 350,
        marginTop: theme.spacing(5),
      },
    },
    content: {},
    video: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
    },
    mobileWrapper: { display: 'flex', flexDirection: 'column' },
    whiteContent: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    whiteContentTitle: {
      fontSize: theme.typography.h1.fontSize,
      color: theme.palette.common.black,
      fontWeight: 'bold',
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h2.fontSize,
        marginBottom: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: theme.spacing(3),
      },
    },
    whiteContentText: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 600,
      color: theme.palette.common.black,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    whiteContentSubText: {
      width: 270,
      fontSize: theme.typography.h4.fontSize,
      color: theme.palette.common.black,
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
        width: 250,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
        width: 230,
      },
    },
    purpleContent: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    purpleContentTitle: {
      fontSize: theme.typography.h1.fontSize,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h2.fontSize,
        marginBottom: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: theme.spacing(3),
      },
    },
    purpleContentText: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 600,
      color: theme.palette.common.white,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    purpleContentSubText: {
      width: 270,
      fontSize: theme.typography.h4.fontSize,
      color: theme.palette.common.white,
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
        width: 250,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
        width: 230,
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    pointWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 1000,
      [theme.breakpoints.down('md')]: {
        width: 900,
      },
      [theme.breakpoints.down('sm')]: {
        width: 730,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: 350,
      },
    },
    point: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    largeImg: {
      width: 500,
      height: 330,
      [theme.breakpoints.down('md')]: {
        width: 400,
        height: 250,
      },
      [theme.breakpoints.down('sm')]: {
        width: 300,
        height: 200,
      },
    },
    mediumImg: {
      width: 400,
      borderRadius: 50,
      marginRight: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        width: 325,
        borderRadius: 40,
        marginRight: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        width: 250,
        borderRadius: 30,
        marginRight: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        width: 250,
        borderRadius: 30,
        marginRight: theme.spacing(0),
      },
    },
    smallImg: {
      maxWidth: 300,
      height: 200,
      borderRadius: 50,
      [theme.breakpoints.down('md')]: {
        maxWidth: 250,
        height: 166.25,
        borderRadius: 40,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 200,
        height: 133.33,
        borderRadius: 30,
      },
    },
    avatar: {
      width: 100,
      height: 100,
      [theme.breakpoints.down('md')]: {
        width: 80,
        height: 80,
      },
      [theme.breakpoints.down('sm')]: {
        width: 70,
        height: 70,
      },
    },
    link: { color: theme.palette.common.white },
    intro: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    problem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    solution: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    features: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    flow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    messages: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    action: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    registerButton: {
      width: 300,
      height: 48,
      fontSize: theme.typography.h4.fontSize,
      backgroundColor: theme.palette.common.white,
      borderRadius: 10,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
        width: 250,
        height: 40,
        borderRadius: 10,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
        width: 200,
        height: 40,
        borderRadius: 7,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
        width: 175,
        height: 32,
      },
    },
    buttonLink: {
      textDecoration: 'none',
    },
    registerText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    qa: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    qaWrapper: {
      width: 600,
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        width: 350,
        marginBottom: theme.spacing(3),
      },
    },
    qaTextWrapper: { display: 'flex', marginBottom: theme.spacing(1) },
    qaIcon: {
      marginRight: theme.spacing(1),
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    qaText: {
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
  })
);

export default LandingStyle;
