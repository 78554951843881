import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const SignUpStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: theme.spacing(5) },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    titleText: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: '12px 16px',
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
