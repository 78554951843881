import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { selectMyProfile } from '../../../stores/slices/userSlice';
import { fetchSessionBody } from './utils/paymentMethods';
import PaymentTableStyle from './PaymentTable.style';
import { stripePublicApiKey } from './config';

interface PropTypes {
  payMode: string;
  buttonDisabled: boolean;
}

const stripePromise = loadStripe(stripePublicApiKey);

const PaymentTable: React.VFC<PropTypes> = ({ payMode, buttonDisabled }) => {
  const classes = PaymentTableStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const userData = useSelector(selectMyProfile);
  const userId = userData.id;

  const redirectToCheckout = async (sessionType: string) => {
    setIsLoading(true);
    setDisabled(true);
    try {
      const fetchSession = async () => {
        const apiName = 'stripeAPI';
        const apiEndpoint = '/checkout';
        const body = fetchSessionBody(userId, sessionType);
        const session = await API.post(apiName, apiEndpoint, { body });
        return session;
      };
      const session = await fetchSession();
      const sessionId = session.id;
      const stripe = await stripePromise;
      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setDisabled(false);
  };

  return (
    <div className={classes.priceTable}>
      <table>
        <colgroup>
          <col className={classes.smallCol} />
          <col className={classes.largeCol} />
          <col className={classes.largeCol} />
          <col className={classes.largeCol} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th className={classes.standardTh}>
              <Typography className={classes.thTextMain}>
                スタンダードプラン
              </Typography>
              <Typography className={classes.thTextSub}>
                徹底的に自走力を
                <br />
                身につけたい方におすすめ
              </Typography>
            </th>
            <th className={classes.minSupportTh}>
              <Typography className={classes.thTextMain}>
                ミニマムサポートプラン
              </Typography>
              <Typography className={classes.thTextSub}>
                最低限のサポートを受けながら
                <br />
                成長したい方におすすめ
              </Typography>
            </th>
            <th className={classes.fullSupportTh}>
              <Typography className={classes.thTextMain}>
                フルサポートプラン
              </Typography>
              <Typography className={classes.thTextSub}>
                最大限のサポートを受けながら
                <br />
                成長したい方におすすめ
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`${classes.borderBottom}`} />
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <div className={`${classes.tableCell}`}>
                <Typography className={classes.price}>
                  {payMode === 'lump' ? '53,800' : '9,800'}
                  <span className={classes.priceSpan}>
                    円{payMode === 'lump' ? '' : '/月'}(税込み)
                  </span>
                </Typography>
                <Typography className={classes.priceMerit}>
                  {payMode === 'lump' ? (
                    <React.Fragment>
                      月々支払いと比較して
                      <br />
                      <span className={classes.priceHighlight}>5,000円</span>
                      お得
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      月額課金なので
                      <br />
                      自分のペースで学習できる
                    </React.Fragment>
                  )}
                </Typography>
                <Button
                  type="button"
                  onClick={() => {
                    payMode === 'lump'
                      ? redirectToCheckout('fetchStandardLump')
                      : redirectToCheckout('fetchStandardInstallments');
                  }}
                  fullWidth
                  disabled={disabled || buttonDisabled}
                  variant="contained"
                  color="primary"
                  className={`${classes.submitButton} ${classes.standardButton}`}
                >
                  {isLoading ? <CircularProgress size={28} /> : 'プランを選択'}
                </Button>
              </div>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <div className={`${classes.tableCell}`}>
                <Typography className={classes.price}>
                  {payMode === 'lump' ? '168,800' : '29,800'}
                  <span className={classes.priceSpan}>
                    円{payMode === 'lump' ? '' : '/月'}(税込み)
                  </span>
                </Typography>
                <Typography className={classes.priceMerit}>
                  {payMode === 'lump' ? (
                    <React.Fragment>
                      月々支払いと比較して
                      <br />
                      <span className={classes.priceHighlight}>10,000円</span>
                      お得
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      月額課金なので
                      <br />
                      自分のペースで学習できる
                    </React.Fragment>
                  )}
                </Typography>
                <Button
                  type="button"
                  onClick={() => {
                    payMode === 'lump'
                      ? redirectToCheckout('fetchMinimumLump')
                      : redirectToCheckout('fetchMinimumInstallments');
                  }}
                  fullWidth
                  disabled={disabled || buttonDisabled}
                  variant="contained"
                  color="primary"
                  className={`${classes.submitButton} ${classes.minSupportButton}`}
                >
                  {isLoading ? <CircularProgress size={28} /> : 'プランを選択'}
                </Button>
              </div>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <div className={classes.tableCell}>
                <Typography className={classes.price}>
                  {payMode === 'lump' ? '398,800' : '69,800'}
                  <span className={classes.priceSpan}>
                    円{payMode === 'lump' ? '' : '/月'}(税込み)
                  </span>
                </Typography>
                <Typography className={classes.priceMerit}>
                  {payMode === 'lump' ? (
                    <React.Fragment>
                      月々支払いと比較して
                      <br />
                      <span className={classes.priceHighlight}>20,000円</span>
                      お得
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      月額課金なので
                      <br />
                      自分のペースで学習できる
                    </React.Fragment>
                  )}
                </Typography>
                <Button
                  type="button"
                  onClick={() => {
                    payMode === 'lump'
                      ? redirectToCheckout('fetchFullLumpBody')
                      : redirectToCheckout('fetchFullInstallments');
                  }}
                  fullWidth
                  disabled={disabled || buttonDisabled}
                  // 申し込みを不可にする場合
                  // disabled={true || buttonDisabled}
                  variant="contained"
                  color="primary"
                  className={`${classes.submitButton} ${classes.fullSupportButton}`}
                >
                  {isLoading ? <CircularProgress size={28} /> : 'プランを選択'}
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${classes.borderBottom}`}>
              <Typography className={classes.featureTitle}>課題閲覧</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
          </tr>
          <tr>
            <td className={`${classes.borderBottom}`}>
              <Typography className={classes.featureTitle}>
                塾生限定
                <br />
                コミュニティ参加
              </Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
          </tr>
          <tr>
            <td className={`${classes.borderBottom}`}>
              <Typography className={classes.featureTitle}>
                塾生限定
                <br />
                勉強会参加
              </Typography>
              <Typography className={classes.featureTitleSub}>
                ※毎週1回開催
              </Typography>
              <Typography className={classes.featureTitleSub}>
                ※質問数無制限
              </Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
          </tr>
          <tr>
            <td className={`${classes.borderBottom}`}>
              <Typography className={classes.featureTitle}>課題添削</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
          </tr>
          <tr>
            <td className={`${classes.borderBottom}`}>
              <Typography className={classes.featureTitle}>
                講師による
                <br />
                チャットサポート
              </Typography>
              <Typography className={classes.featureTitleSub}>
                ※24h以内に返信
              </Typography>
              <Typography className={classes.featureTitleSub}>
                ※質問数無制限
              </Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>×</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>◎</Typography>
            </td>
          </tr>
          <tr>
            <td className={`${classes.borderBottom}`}>
              <Typography className={classes.featureTitle}>
                個別技術指導
              </Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>×</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureTrueOrFalse}>×</Typography>
            </td>
            <td className={`${classes.borderBottom} ${classes.borderLeft} `}>
              <Typography className={classes.featureText}>毎週60分</Typography>
            </td>
          </tr>
          <tr>
            <td />
            <td className={`${classes.borderLeft} `}>
              <div className={classes.tableCell}>
                <Typography className={classes.featureTextSub}>
                  <span className={classes.featureTextHighlight}>
                    申し込み受付中
                  </span>
                </Typography>
                <Button
                  type="button"
                  onClick={() => {
                    payMode === 'lump'
                      ? redirectToCheckout('fetchStandardLump')
                      : redirectToCheckout('fetchStandardInstallments');
                  }}
                  fullWidth
                  disabled={disabled || buttonDisabled}
                  variant="contained"
                  color="primary"
                  className={`${classes.submitButton} ${classes.standardButton}`}
                >
                  {isLoading ? <CircularProgress size={28} /> : 'プランを選択'}
                </Button>
              </div>
            </td>
            <td className={`${classes.borderLeft}`}>
              <div className={classes.tableCell}>
                <Typography className={classes.featureTextSub}>
                  <span className={classes.featureTextHighlight}>
                    申し込み受付中
                  </span>
                </Typography>
                <Button
                  type="button"
                  onClick={() => {
                    payMode === 'lump'
                      ? redirectToCheckout('fetchMinimumLump')
                      : redirectToCheckout('fetchMinimumInstallments');
                  }}
                  fullWidth
                  disabled={disabled || buttonDisabled}
                  variant="contained"
                  color="primary"
                  className={`${classes.submitButton} ${classes.minSupportButton}`}
                >
                  {isLoading ? <CircularProgress size={28} /> : 'プランを選択'}
                </Button>
              </div>
            </td>
            <td className={`${classes.borderLeft}`}>
              <div className={classes.tableCell}>
                <Typography className={classes.featureTextSub}>
                  <span className={classes.featureTextHighlight}>
                    申し込み受付中
                  </span>
                </Typography>
                <Button
                  type="button"
                  onClick={() => {
                    payMode === 'lump'
                      ? redirectToCheckout('fetchFullLumpBody')
                      : redirectToCheckout('fetchFullInstallments');
                  }}
                  fullWidth
                  disabled={disabled || buttonDisabled}
                  // 申し込みを不可にする場合
                  // disabled={true || buttonDisabled}
                  variant="contained"
                  color="primary"
                  className={`${classes.submitButton} ${classes.fullSupportButton}`}
                >
                  {isLoading ? <CircularProgress size={28} /> : 'プランを選択'}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentTable;
