import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { useMediaQuery } from 'react-responsive';

type PropTypes = {
  url: string;
};

const DisplayVideo: React.VFC<PropTypes> = ({ url }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width:600px)',
  });
  const videoWidth = isMobile ? 250 : 600;
  const videoHeight = isMobile ? 136 : 337.5;
  return (
    <React.Fragment>
      <ReactPlayer url={url} width={videoWidth} height={videoHeight} />
    </React.Fragment>
  );
};

export default DisplayVideo;
