import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { PROPS_CONFIRM } from '../../../../interfaces/slice-props.interface';
import paths from '../../../../config/paths';
import history from '../../../../history';
import { selectAuthEmail } from '../../../../stores/slices/userSlice';
import { confirmSignUp } from '../utils/userAuth';
import ConfirmStyle from './Confirm.style';

const Confirm: React.VFC = () => {
  const classes = ConfirmStyle();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const authEmail = useSelector(selectAuthEmail);
  const { register, handleSubmit, errors } = useForm<PROPS_CONFIRM>();

  const handleConfirm = async (data: PROPS_CONFIRM) => {
    setLoading(true);
    setDisabled(true);
    // const { email, code } = data;
    try {
      setLoading(true);
      setDisabled(true);
      const { email, code } = data;
      try {
        await confirmSignUp(email, code);
        history.push(paths.userAuth.thank);
        setLoading(false);
        setDisabled(false);
      } catch (error) {
        switch (error.message) {
          case 'Invalid verification code provided, please try again.':
            alert('認証コードが違います。お確かめの上もう一度ご入力ください');
            break;
          case 'Username/client id combination not found.':
            alert(
              '登録されていないメールアドレスです。先に会員登録をお願いいたします。'
            );
            break;
          default:
            alert('認証に失敗しました。時間をおいた後再度お試しください');
        }
        setLoading(false);
        setDisabled(false);
      }
    } catch (error) {
      switch (error.message) {
        case 'Invalid verification code provided, please try again.':
          alert('認証コードが違います。お確かめの上もう一度ご入力ください');
          break;
        case 'Username/client id combination not found.':
          alert(
            '登録されていないメールアドレスです。先に会員登録をお願いいたします。'
          );
          break;
        default:
          alert('認証に失敗しました。時間をおいた後再度お試しください');
      }
      setLoading(false);
      setDisabled(false);
    }
  };
  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography align="center" className={classes.titleText}>
            ご登録いただいたメールアドレス宛に
            <br />
            お送りした認証コードをご入力ください
          </Typography>
          <form onSubmit={handleSubmit(handleConfirm)} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="メールアドレス"
                  disabled={disabled}
                  name="email"
                  autoComplete="email"
                  defaultValue={authEmail}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'メールアドレスを入力してください',
                    },
                    pattern: {
                      value: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                      message: 'メールアドレスを正しい形式で入力してください',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="code"
                  label="認証コード"
                  disabled={disabled}
                  type="code"
                  id="code"
                  autoComplete="current-code"
                  error={Boolean(errors.code)}
                  helperText={errors.code && errors.code.message}
                  inputRef={register({
                    required: {
                      value: true,
                      message: '認証コードを入力してください',
                    },
                  })}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabled}
              color="primary"
              className={classes.submit}
            >
              {loading ? <CircularProgress size={28} /> : '認証する'}
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to={paths.userAuth.signUp}>
                  アカウントを持っていない方
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Confirm;
