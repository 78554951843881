import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { selectAuthEmail } from '../../../../stores/slices/userSlice';
import { signIn } from '../utils/userAuth';
import { PROPS_SIGNIN } from '../../../../interfaces/slice-props.interface';
import { SignInStyle } from './SignIn.style';
import paths from '../../../../config/paths';

const SignIn: React.VFC = () => {
  const classes = SignInStyle();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const authEmail = useSelector(selectAuthEmail);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { register, handleSubmit, errors } = useForm<PROPS_SIGNIN>();
  const handleSignIn = async (data: PROPS_SIGNIN) => {
    setLoading(true);
    setDisabled(true);
    const { email, password } = data;
    try {
      await signIn(email, password);
      window.location.href = `${paths.curriculum.list}`;
    } catch (error) {
      switch (error.message) {
        case 'User does not exist.':
          alert('ユーザが存在しません。メールアドレスをご確認ください。');
          break;
        case 'Incorrect username or password.':
          alert(
            'ログインに失敗しました。メールアドレスまたはパスワードをご確認ください。'
          );
          break;
        default:
          alert('ログインに失敗しました。時間をおいた後再度お試しください');
      }
      setLoading(false);
      setDisabled(false);
    }
  };
  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.titleText}>ログイン</Typography>
          <form onSubmit={handleSubmit(handleSignIn)} className={classes.form}>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              margin="normal"
              id="email"
              label="メールアドレス"
              disabled={disabled}
              name="email"
              autoComplete="email"
              defaultValue={authEmail}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              inputRef={register({
                required: {
                  value: true,
                  message: 'メールアドレスを入力してください',
                },
                pattern: {
                  value: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                  message: 'メールアドレスを正しい形式で入力してください',
                },
              })}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              name="password"
              label="パスワード"
              disabled={disabled}
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password.message}
              inputRef={register({
                required: {
                  value: true,
                  message: 'パスワードを入力してください',
                },
                minLength: {
                  value: 8,
                  message: 'パスワードは8文字以上で入力してください',
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              disabled={disabled}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading ? <CircularProgress size={28} /> : 'ログインする'}
            </Button>
            {/* 第二期生の募集が完了したらコメントアウトを解除する */}
            <Grid container justify="flex-end">
              <Grid item>
                <Link to={paths.userAuth.signUp}>
                  アカウントを持っていない方
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default SignIn;
